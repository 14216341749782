import { post, get, put, del } from "./utils.service";

export const getBuildingsForSite = async (hash) => {
    const res = await get(`/setup/buildings/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const getBuildingDocuments = async (buildingId) => {
    const res = await get(`/setup/documents/${buildingId}`);
    return await res.json();
};

export const updateBuildingDocuments = async (body, hash) => {
    const res = await post(`/setup/documents`, { ...body }, { SiteHash: hash });
    return await res.json();
};

export const verifyDoe = async (body) => {
    const res = await post(`/setup/verifyDoe`, { ...body });
    return await res.json();
};

export const getIntesisTreated = async (configuration) => {
    const res = await post(`/setup/intesisTreated`, { configuration });
    return await res.json();
};

export const getConfigOptions = async (hash) => {
    const res = await get(`/setup/config/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const createConfiguration = async (body, hash) => {
    const res = await post(
        `/setup/createConfiguration`,
        { ...body },
        { SiteHash: hash }
    );
    return await res.json();
};

export const deleteConfiguration = async (id) => {
    const res = await del(`/setup/deleteConfiguration/${id}`);
    return await res.json();
};

export const getExistingConfigurations = async (hash) => {
    const res = await get(`/setup/configurations/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const modifySetup = async (body) => {
    const res = await post(`/setup/modifySetup`, { ...body });
    return await res.json();
};

export const requestData = async (body) => {
    const res = await post(`/setup/requestData`, { ...body });
    return await res.json();
};

export const setJeedomIds = async (body) => {
    const res = await post(`/setup/setJeedomIds`, { ...body });
    return await res.json();
};

export const triggerSensors = async (body) => {
    const res = await post(`/setup/triggerSensors`, { ...body });
    return await res.json();
};

export const triggerSensorsStatus = async (boxId) => {
    const res = await get(`/setup/triggerSensorsStatus/${boxId}`);
    return await res.json();
};

export const getEvent = async (eventId) => {
    const res = await get(`/setup/event/${eventId}`);
    return await res.json();
};

export const removeSocket = async (body) => {
    const res = await post(`/setup/removeSocket`, { ...body });
    return await res.json();
};

export const connectPlugin = async (body) => {
    const res = await post(`/setup/connect`, { ...body });
    return await res.json();
};

export const initPlugin = async (body) => {
    const res = await post(`/setup/init`, { ...body });
    return await res.json();
};

export const statusPlugin = async (body) => {
    const res = await post(`/setup/status`, { ...body });
    return await res.json();
};
