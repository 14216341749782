import { createSlice } from "@reduxjs/toolkit";

const DesignSlice = createSlice({
    name: "design",
    initialState: {
        selectedSite: null,
        selectedBuilding: null,
        objects: [],
    },
    reducers: {
        setSelectedSite: (state, { payload }) => {
            return {
                ...state,
                selectedSite: payload,
            };
        },
        setSelectedBuilding: (state, { payload }) => {
            return {
                ...state,
                selectedBuilding: payload,
            };
        },
    },
});

export default DesignSlice;
