import { createSlice } from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
    name: "global",
    initialState: {
        path: null,
        mode: "normal",
        width: 1200,
        surfaceCategories: [],
        windowShutterTypes: [],
    },
    reducers: {
        setPath: (state, { payload }) => {
            return {
                ...state,
                path: payload,
            };
        },
        setMode: (state, { payload }) => {
            return {
                ...state,
                mode: payload,
            };
        },
        setWidth: (state, { payload }) => {
            return {
                ...state,
                width: payload,
            };
        },
        setSurfaceCategories: (state, { payload }) => {
            return {
                ...state,
                surfaceCategories: payload,
            };
        },

        setWindowShutterTypes: (state, { payload }) => {
            return {
                ...state,
                windowShutterTypes: payload,
            };
        },
    },
});

export default GlobalSlice;
