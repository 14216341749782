import { post, get, put, del } from "./utils.service";
import { logout } from "./auth.service";

export const getMe = async () => {
    const res = await get(`/users/me`);
    return await res.json();
};

export const getSites = async () => {
    const res = await get(`/users/sites`);
    if (res?.status == 401) {
        console.log("logout");
        await logout();
    } else {
        return await res.json();
    }
};

export const updateMe = async (body) => {
    const res = await post(`/users/me`, { ...body });
    return await res.json();
};

export const changePassword = async (body) => {
    const res = await post(`/users/password`, { ...body });
    return await res.json();
};
