import * as moment from "moment";

export const verifyUser = ({ Email, LastName, FirstName }) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(Email) && LastName && FirstName;
};

export const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const roundHalf = (num) => {
    return Math.round(num * 2) / 2;
};

export const formatNumberByType = (type, x) => {
    if (type.includes("TEMPERATURE")) return Math.round(x);
    else if (typeof x === "number") return x.toFixed(2);
    else return x;
};

export const colorTypes = {
    ThermalZone: "#2DCAD5",
    Building: "#53D186",
    Floor: "#4285F4",
    Site: "#3f51b5",
    SubZone: "#ea80fc",
    ConsumptionUnit: "#e0f2f1",
    Other: "#f0f4c3",
    Room: "#dcedc8",
};

export const getNewDateRange = (start, end) => {
    // let calendarStore = store.getState().calendarStore;
    // let mode = calendarStore.mode;
    // console.log("receiving", start, end, mode);
    // if (mode == "day" && start && end) {
    //     return {
    //         start,
    //         end,
    //     };
    // } else if (start && !end) {
    //     let momentStart = createMomentDate(start)
    //         .startOf(mode)
    //         .format("YYYY-MM-DD");
    //     let momentEnd = createMomentDate(start)
    //         .endOf(mode)
    //         .format("YYYY-MM-DD");
    //     return {
    //         start: extractDateFromMomentDate(momentStart),
    //         end: extractDateFromMomentDate(momentEnd),
    //     };
    // } else if (start && end && start.day && end.day) {
    //     let momentStart = createMomentDate(start)
    //         .startOf(mode)
    //         .format("YYYY-MM-DD");
    //     let momentEnd = createMomentDate(end).endOf(mode).format("YYYY-MM-DD");
    //     return {
    //         start: extractDateFromMomentDate(momentStart),
    //         end: extractDateFromMomentDate(momentEnd),
    //     };
    // } else {
    //     let today = moment();
    //     let momentStart = moment(today).startOf(mode).format("YYYY-MM-DD");
    //     let momentEnd = moment(today).endOf(mode).format("YYYY-MM-DD");
    //     return {
    //         start: extractDateFromMomentDate(momentStart),
    //         end: extractDateFromMomentDate(momentEnd),
    //     };
    // }
};

export const createMomentDate = (date) => {
    let newDate = new Date(date.year, parseInt(date.month) - 1, date.day);
    return moment(newDate);
};

export const extractDateFromMomentDate = (momentDate) => {
    let newDate = moment(momentDate).format("YYYY-MM-DD");
    return {
        day: newDate.split("-")[2],
        month: parseInt(newDate.split("-")[1]),
        year: newDate.split("-")[0],
    };
};

export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};

export const extractList = (list) => {
    //List: "0|Cool;1|Heat;2|Dry;3|Fan;4|Auto"
    let output = {};
    let array = list.split(";");
    for (let i = 0; i < array.length; i++) {
        let item = array[i].split("|");
        if (item.length == 2) {
            output[item[0]] = item[1];
        }
    }
    console.log("output", output);
    return output;
};

export const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const computeU = (layers, degradationFactor) => {
    let variable_R = 0;
    layers.map((el) => {
        variable_R += (el.Width / 100) * el.Conductivity;
    });
    if (variable_R) return 1 / (variable_R * degradationFactor);
    else return undefined;
};

export const getCookie = (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
};