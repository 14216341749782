import { post, get, put, del } from "./utils.service";

export const getTypes = async () => {
    const res = await get(`/sites/types`);
    return await res.json();
};

export const getSites = async () => {
    const res = await get(`/site/sites`);
    return await res.json();
};

export const getBuildings = async (hash) => {
    const res = await get(`/sites/${hash}/buildings`);
    return await res.json();
};

export const updateSite = async (body, hash) => {
    const res = await post(`/sites/update/${hash}`, body, {
        SiteHash: hash,
    });
    return await res.json();
};

export const createSite = async (body) => {
    const res = await post(`/site/create`, body);
    return await res.json();
};

export const getSiteObjects = async (hash) => {
    const res = await get(`/sites/objects/${hash}`);
    return await res.json();
};

export const saveSiteObjects = async (body) => {
    const res = await post(`/sites/objects`, body);
    return await res.json();
};

export const createDesign = async (body, siteHash, buildingHash) => {
    const res = await post(`/sites/design/${siteHash}/${buildingHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const createOrUpdateGeoJson = async (body, siteHash, buildingHash) => {
    const res = await post(`/sites/geoJson/${siteHash}/${buildingHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const updateLinks = async (body, siteHash) => {
    const res = await post(`/sites/updateLinks/${siteHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const getMaterials = async (siteHash) => {
    const res = await get(`/sites/materials/${siteHash}`, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const postMaterials = async (body, siteHash) => {
    const res = await post(`/sites/materials/${siteHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const getGlobals = async () => {
    const res = await get(`/sites/globals`);
    return await res.json();
};

export const getConstructions = async (siteHash) => {
    const res = await get(`/sites/constructions/${siteHash}`, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const postConstructions = async (body, siteHash) => {
    const res = await post(`/sites/constructions/${siteHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const getWindowConstructions = async (siteHash) => {
    const res = await get(`/sites/windowConstructions/${siteHash}`, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const postWindowConstructions = async (body, siteHash) => {
    const res = await post(`/sites/windowConstructions/${siteHash}`, body, {
        SiteHash: siteHash,
    });
    return await res.json();
};

export const generateZip = async (hash) => {
    const res = await get(`/sites/zip/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const editingStatus = async (hash) => {
    const res = await get(`/sites/editingStatus/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const getSite = async (hash) => {
    const res = await get(`/sites/${hash}`, { SiteHash: hash });
    return await res.json();
};

export const electricitySupplierConsent = async (body, siteHash) => {
    const res = await post(
        `/sites/electricitySupplierConsent/${siteHash}`,
        body,
        {
            SiteHash: siteHash,
        }
    );
    return await res.json();
};
