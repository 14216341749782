import { useEffect, useState } from "react";
import "./App.css";

import AuthNavigator from "./navigation/auth.navigator";
import AdminNavigator from "./navigation/admin.navigator";
import GlobalSlice from "./store/slices/global.slice";
import { setLocale } from "./utils/locale/locale.utils";
import { getGlobals } from "./services/site.service";
import { Provider } from "react-redux";
import { store } from "./store/configure.store";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import * as moment from "moment";

const theme = createTheme({
    palette: {
        primary: {
            main: "rgb(66, 133, 244)",
        },
        secondary: {
            main: "#E33E7F",
        },
    },
});

function App() {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const initGlobal = async () => {
        let res = await getGlobals();
        if (res.surfaceCategories) {
            store.dispatch(
                GlobalSlice.actions.setSurfaceCategories(res.surfaceCategories)
            );
        }
        if (res.windowShutterTypes) {
            store.dispatch(
                GlobalSlice.actions.setWindowShutterTypes(
                    res.windowShutterTypes
                )
            );
        }
    };

    const initLocale = async () => {
        let language = localStorage.getItem("language");
        if (!language) {
            language = "fr";
            localStorage.setItem("language", language);
        }
        await setLocale(language);
        moment.locale(language);
    };
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let curr_token = localStorage.getItem("token");
        if (curr_token) {
            setToken(curr_token);
        }
        initLocale();
        initGlobal();
        setLoading(false);
    };

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                {loading ? (
                    <></>
                ) : token ? (
                    <AdminNavigator />
                ) : (
                    <AuthNavigator />
                )}
            </ThemeProvider>
        </Provider>
    );
}

export default App;
