import { createSlice } from "@reduxjs/toolkit";

const MaterialsSlice = createSlice({
    name: "me",
    initialState: {
        key: "materials",
    },
    reducers: {
        setKey: (state, { payload }) => {
            return { key: payload };
        },
    },
});

export default MaterialsSlice;
