import React, { useState } from "react";
/** COMPONENTS */
import { Row } from "reactstrap";
import Dropzone from "react-dropzone";
/** SERVICES */
import { uploadFile } from "../../../../services/s3.service";

const DocumentsForm = (props) => {
    const [addedFiles, setAddedFiles] = useState([...props.documents]);
    const onDrop = async (acceptedFiles) => {
        console.log(acceptedFiles, addedFiles);
        let prevFiles = [...addedFiles, ...acceptedFiles];
        for (let i = 0; i < prevFiles.length; i++) {
            prevFiles[i].DocumentType = props.DocumentType;
            if (!prevFiles[i].Name) prevFiles[i].Name = prevFiles[i].name;
            if (!prevFiles[i].type) {
                try {
                    console.log("extension", prevFiles[i]);
                    let path = prevFiles[i].path || prevFiles[i].Path;
                    let extension = path.split(".").reverse()[0];
                    console.log("extension", extension);
                    if (
                        extension &&
                        ["xlsm", "xlsx", "xls", "doc", "docx"].indexOf(
                            extension
                        ) >= 0
                    )
                        prevFiles[i].Type = extension;
                    else {
                        prevFiles[i].Type = "txt";
                    }
                } catch (e) {
                    console.log("e", e);
                    prevFiles[i].Type = "txt";
                }
            }
        }
        // TO REMOVE
        //await uploadFile(acceptedFiles[0]);
        setAddedFiles(prevFiles);
        props.onDrop(prevFiles);
    };
    const removeFile = (index) => {
        const files = [...addedFiles];
        files.splice(index, 1);
        setAddedFiles(files);
        props.onDrop(files);
    };
    return (
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div
                        {...getRootProps({
                            onClick: (e) => {
                                if (
                                    e.target.id.includes("file") ||
                                    e.target.id.includes("remove")
                                ) {
                                    e.stopPropagation();
                                }
                            },
                        })}
                        style={{ ...styles.container }}
                    >
                        <input {...getInputProps()} />
                        <h3>{props.title}</h3>
                        <p>
                            {addedFiles.length === 0 ? (
                                <div style={{ marginTop: 30 }}>
                                    Déposer des fichiers
                                </div>
                            ) : (
                                addedFiles.map((el, index) => (
                                    <Row style={{ alignItems: "center" }}>
                                        <i
                                            className="fa fa-times"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                removeFile(index);
                                            }}
                                            id={`remove-${index}`}
                                            style={{
                                                cursor: "pointer",
                                                padding: 2,
                                                marginRight: 10,
                                                color: "red",
                                            }}
                                        />
                                        <a
                                            href={el.Path}
                                            target="_blank"
                                            id={`file-${index}`}
                                        >
                                            {el.Name}
                                        </a>
                                    </Row>
                                ))
                            )}
                        </p>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

const styles = {
    container: {
        width: "100%",
        height: "100%",
        minHeight: 360,
        border: "2px dotted #ccc",
        cursor: "pointer",
        marginBottom: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
    },
};

export default DocumentsForm;
