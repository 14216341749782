import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  getConfigOptions,
  createConfiguration,
} from "../../../services/setup.service";
import { sleep } from "../../../utils/global.utils";

import Mitsu_Bms_Stepper from "./plugins/mitsu_bms/stepper.component";
import Daikin_129_Stepper from "./plugins/daikin_129/stepper.component";
import Fujitsu_uty_Stepper from "./plugins/fujitsu_uty/stepper.component";

const ModalComponent = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [plugins, setPlugins] = useState([]);
  const [connectorTypes, setConnectorTypes] = useState([]);
  //const [protocols, setProtocols] = useState([]);
  const [dataTypeFamilies, setDataTypeFamilies] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [configuration, setConfiguration] = useState(props.configuration || {});
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    console.log("old config 1>>", configuration);
    let res = await getConfigOptions(props.site.Hash);
    if (res) {
      setPlugins(res.plugins || []);
      //setProtocols(res.protocols || []);
      setDataTypeFamilies(res.dataTypeFamilies || []);
      setConnectorTypes(res.connectorTypes || []);
      setBoxes(res.boxes || []);
      console.log("old config 2>>", configuration);
      let newConfig = { ...configuration };
      if (!newConfig.PluginId && res.plugins.length > 0) {
        newConfig.PluginId = res.plugins[0].PluginId;
      }
      if (!newConfig.ConnectorTypeId && res.connectorTypes.length > 0) {
        newConfig.ConnectorTypeId = res.connectorTypes[0].ConnectorTypeId;
      }

      if (!newConfig.DataTypeFamilyId && res.dataTypeFamilies.length > 0) {
        newConfig.DataTypeFamilyId = res.dataTypeFamilies[0].DataTypeFamilyId;
      }

      if (!newConfig.BoxId && res.boxes.length > 0) {
        newConfig.BoxId = res.boxes[0].BoxId;
      }
      if (!newConfig.SiteId) {
        newConfig.SiteId = props.site?.SiteId;
      }
      setConfiguration({ ...configuration, ...newConfig });
    }
  };

  const handleNext = () => {
    setError("");
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  const submitConfiguration = async () => {
    setLoading(true);
    setLoadingMessage("Enregistrement des modifications");
    await sleep(1000);
    const body = {
      SiteId: props.site?.SiteId,
      ...configuration,
    };
    console.log("body", body);
    let res = await createConfiguration(body, props.site.Hash);
    if (res && res.configuration) {
      setConfiguration(res.configuration);
      setLoading(false);
      setLoadingMessage("");
      handleNext();
    } else {
      setLoading(false);
      setLoadingMessage("");
      setError(res.errorMessage);
    }
  };

  const handleBack = () => {
    setError("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateConfig = (key, value) => {
    let input = { [key]: value };
    if (key == "PluginId") {
      // we must also modify the connector type
      let find = connectorTypes.find((el) => el.PluginId == value);

      if (find) {
        input = { ...input, ConnectorTypeId: find.ConnectorTypeId };
      }
      console.log("connectorTypes", connectorTypes, find, input);
    }
    setConfiguration((configuration) => {
      return { ...configuration, ...input };
    });
  };

  return (
    <Modal isOpen={props.modal} size={"lg"}>
      <ModalHeader
        toggle={() => {
          //props.setModal(false);
          window.location.reload();
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 20 }}>
            {configuration?.ConfigurationId
              ? "Edition"
              : "Nouvelle configuration"}
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        {configuration?.PluginId == 275 && (
          <Mitsu_Bms_Stepper
            plugins={plugins}
            configuration={configuration}
            connectorTypes={connectorTypes}
            dataTypeFamilies={dataTypeFamilies}
            boxes={boxes}
            updateConfig={updateConfig}
            hash={props.site?.Hash}
            submitConfiguration={submitConfiguration}
            activeStep={activeStep}
            error={error}
            loading={loading}
            loadingMessage={loadingMessage}
            handleBack={handleBack}
          />
        )}

        {configuration?.PluginId == 277 && (
          <Daikin_129_Stepper
            plugins={plugins}
            configuration={configuration}
            connectorTypes={connectorTypes}
            dataTypeFamilies={dataTypeFamilies}
            boxes={boxes}
            updateConfig={updateConfig}
            hash={props.site?.Hash}
            submitConfiguration={submitConfiguration}
            activeStep={activeStep}
            error={error}
            loading={loading}
            loadingMessage={loadingMessage}
            handleBack={handleBack}
          />
        )}

         {configuration?.PluginId == 278 && (
          <Fujitsu_uty_Stepper
            plugins={plugins}
            configuration={configuration}
            connectorTypes={connectorTypes}
            dataTypeFamilies={dataTypeFamilies}
            boxes={boxes}
            updateConfig={updateConfig}
            hash={props.site?.Hash}
            submitConfiguration={submitConfiguration}
            activeStep={activeStep}
            error={error}
            loading={loading}
            loadingMessage={loadingMessage}
            handleBack={handleBack}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalComponent;
