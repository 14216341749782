import React, { useEffect, useState } from "react";
/** COMPONENTS */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

const TreeComponent = (props) => {
    const getNodeKey = ({ node }) => node.id;
    return (
        <div style={{ height: 400 }}>
            <SortableTree
                treeData={props.data}
                onChange={(treeData) => {
                    props.setData(treeData);
                }}
                getNodeKey={getNodeKey}
                canDrag={props.canDrag}
                isVirtualized={false}
                generateNodeProps={({ node, path }) => {
                    // onClick: () => {
                    //     selectNode(node, path);
                    //     console.log("click", node);
                    // },
                    let found = props.currentSelection?.[props.index - 1]?.find(
                        (el) => el.id == node.id
                    );
                    return {
                        style: {
                            border: found ? "2px solid red" : "",
                            width: "100%",
                        },
                        title: (
                            <Node
                                node={node}
                                path={path}
                                nodeClick={() =>
                                    props.nodeClick(props.index, node, path)
                                }
                                currentSelection={props.currentSelection}
                                index={props.index}
                                selections={props.selections}
                                changeNodeSpaceType={props.changeNodeSpaceType}
                            />
                        ),
                    };
                }}
            />
        </div>
    );
};

export default TreeComponent;

const Node = (props) => {
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        let found = false;
        let selection = props?.selections?.map((el) => {
            let target = el[props.index - 1];
            let find = target.find((item) => item.id == props?.node.id);
            if (find) {
                found = true;
            }
        });
        setSelected(found);
    }, [props.selections]);
    return (
        <div
            style={{
                cursor: "pointer",
                width: "100%",
                color: selected ? "green" : "",
            }}
            onClick={() => {
                props.nodeClick();
            }}
        >
            <span>{props?.node?.title}</span>
            {selected && (
                <i
                    className={`fa fa-check-circle`}
                    style={{ marginLeft: 10 }}
                />
            )}
            {props?.changeNodeSpaceType && (
                <FormControl variant="standard" style={{ marginLeft: 10 }}>
                    <Select
                        onChange={(e) => {
                            props?.changeNodeSpaceType({
                                value: e.target.value,
                                node: props?.node,
                            });
                        }}
                        defaultValue={props?.node?.spaceType}
                        disableUnderline
                    >
                        <MenuItem value="Hotel_Room">Chambre</MenuItem>
                        <MenuItem value="Common_Area">Zone Commune</MenuItem>
                    </Select>
                </FormControl>
            )}
        </div>
    );
};
