import { environment } from "../utils/environment";
import { store } from "../store/configure.store";

export const queryBuilder = (query) => {
    let queryBuilder = "?";

    Object.keys(query).forEach((key) => {
        queryBuilder += `${key}=${JSON.stringify(query[key])}&`;
    });
    return queryBuilder;
};

export const put = async (route, object) => {
    const token = localStorage.getItem("token");
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = token;
    }
    return await fetch(environment.apiUrl + route, {
        method: "put",
        headers,
        body: JSON.stringify(object),
    });
};

export const get = async (route, reqHeaders) => {
    console.log("environment", process.env, environment);
    const token = window.localStorage.getItem("token");
    const headers = {
        ...reqHeaders,
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = token;
    }
    let mode = localStorage.getItem("AGRID_MODE");
    if (mode == "master") {
        headers["AGRID_MODE"] = mode;
    }
    return await fetch(environment.apiUrl + route, {
        method: "get",
        headers,
    });
};

export const del = async (route) => {
    const token = localStorage.getItem("token");
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = token;
    }
    return await fetch(environment.apiUrl + route, {
        method: "delete",
        headers,
    });
};

export const post = async (route, object, reqHeaders) => {
    const token = localStorage.getItem("token");
    let headers = {
        ...reqHeaders,
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    let mode = localStorage.getItem("AGRID_MODE");
    if (mode == "master") {
        headers["AGRID_MODE"] = mode;
    }
    if (token) {
        headers["Authorization"] = token;
    }
    return await fetch(environment.apiUrl + route, {
        method: "post",
        headers,
        body: JSON.stringify(object),
    });
};
