import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import AuthPage from "../views/auth/auth.page";

const AuthNavigator = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login">
                    <AuthPage />
                </Route>
                <Redirect to="/login" />
            </Switch>
        </Router>
    );
};

export default AuthNavigator;
