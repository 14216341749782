import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import LinkIcon from "@mui/icons-material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { store } from "../../store/configure.store";
import GlobalSlice from "../../store/slices/global.slice";
import { getSites } from "../../services/user.service";
import DesignSlice from "../../store/slices/design.slice";
import MaterialsSlice from "../../store/slices/materials.slice";
import NavSelect from "./nav.select.component";
import { logout } from "../../services/auth.service";
import Logo from "../../assets/img/brand/logo_blue.png";
const drawerWidth = 240;

function Layout(props) {
  const currentPath = useSelector((state) => state.globalStore.path);
  const selectedSite = useSelector((state) => state.designStore.selectedSite);
  const key = useSelector((state) => state.materialsStore.key);
  const selectedBuilding = useSelector(
    (state) => state.designStore.selectedBuilding
  );
  const [sites, setSites] = useState([]);
  const [buildings, setBuildings] = useState([]);
  let history = useHistory();

  React.useEffect(() => {
    let path = window?.location?.pathname;
    console.log("newPath", path);
    store.dispatch(GlobalSlice.actions.setPath(path));
  });

  useEffect(() => {
    fetchSites();
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const fetchSites = async () => {
    let res = await getSites();
    if (res && res.sites && res.sites.length > 0) {
      setSites(res.sites);
      if (query.get("site")) {
        let find = res.sites.find((el) => el.Hash == query.get("site"));
        console.log("find", find);
        if (find) store.dispatch(DesignSlice.actions.setSelectedSite(find));
        else store.dispatch(DesignSlice.actions.setSelectedSite(res.sites[0]));
      } else {
        store.dispatch(DesignSlice.actions.setSelectedSite(res.sites[0]));
      }
    }
  };

  const changeSelectedSite = (hash) => {
    let newSite = sites.find((el) => el.Hash == hash);
    if (newSite) {
      store.dispatch(DesignSlice.actions.setSelectedSite(newSite));
      console.log("currentPath", currentPath);
      history.push({
        pathname: currentPath,
        search: `?site=${newSite.Hash}`,
      });
      if (currentPath === "/admin/masks") window.location.reload();
    }
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <img
          src={Logo}
          alt="agrid_log"
          style={{
            width: 120,
            objectFit: "contain",
            position: "fixed",
            top: 20,
            left: 20,
            cursor: "pointer",
          }}
          onClick={() => {
            history.push({
              pathname: "/",
            });
          }}
        />
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: "Installation", path: "/" },
          // { text: "Diagnostique", path: "/materials" },
          { text: "Relations", path: "/links" },
          // { text: "Masques solaires", path: "/masks" },
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => {
              history.push({
                pathname: item.path,
                search: `?site=${selectedSite.Hash}`,
              });
            }}
          >
            <ListItemIcon>
              {index === 0 && <IntegrationInstructionsIcon />}
              {index === 1 && <WorkspacesIcon />}
              {index === 2 && <LinkIcon />}
              {index === 3 && <WbSunnyIcon />}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button key={"Déconnexion"} onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Déconnexion"} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const onMaterialChange = (value) => {
    store.dispatch(MaterialsSlice.actions.setKey(value));
  };
  return (
    <Box sx={{ display: "flex", zIndex: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ zIndex: 10 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" style={{ color: "white" }}>
            {currentPath?.includes("materials") && (
              <NavSelect
                key="Matériaux"
                label="Matériaux"
                items={[
                  {
                    label: "Choix des matériaux",
                    value: "materials",
                  },
                  {
                    label: "Constructions",
                    value: "constructions",
                  },
                  { label: "Fenêtres", value: "window" },
                  { label: "Associations", value: "groups" },
                ]}
                value={key}
                onChange={(e) => onMaterialChange(e.target.value)}
              ></NavSelect>
            )}
            {!currentPath?.includes("materials") && (
              <Typography variant="h6" noWrap component="div">
                {props.title}
              </Typography>
            )}
            {selectedSite?.Hash && (
              <NavSelect
                label="Sites"
                key="Sites"
                items={sites.map((el) => {
                  return { value: el.Hash, label: el.Name };
                })}
                value={selectedSite?.Hash}
                onChange={(e) => changeSelectedSite(e.target.value)}
              ></NavSelect>
            )}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, zIndex: 1, height: "100%" }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default Layout;
