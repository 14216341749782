import React, { useEffect, useState } from "react";
/** COMPONENTS */
import { Tooltip, Skeleton } from "@mui/material";
/** SERVICES */
import { pingBox } from "../../../services/boxes.service";

const BoxStatusComponent = (props) => {
    const [status, setStatus] = useState("pending");
    useEffect(() => {
        init();
    }, [props.hash]);

    useEffect(() => {
        props.onStatusReceive(status);
    }, [status]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateStatus(props.hash);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const init = async () => {
        setStatus("pending");
        updateStatus(props.hash);
    };

    const updateStatus = async (hash) => {
        console.log("updateStatus", hash);
        if (hash) {
            let response = await pingBox(hash);
            if (response?.success) setStatus("connected");
            else setStatus("disconnected");
        }
    };

    return (
        <Tooltip
            title={
                status === "pending"
                    ? "En attente de la box"
                    : status == "connected"
                    ? "Box connectée"
                    : "Boxe non connectée"
            }
            style={{
                fontSize: 12,
                color: "#e0e0e0",
            }}
        >
            {status == "pending" ? (
                <Skeleton
                    variant="circle"
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                />
            ) : (
                <div
                    style={{
                        width: 20,
                        marginRight: 10,
                        height: 20,
                        borderRadius: 20,
                        background:
                            status === "pending"
                                ? "#f57c00"
                                : status == "connected"
                                ? "#76ff03"
                                : "red",
                    }}
                ></div>
            )}
        </Tooltip>
    );
};

export default BoxStatusComponent;
