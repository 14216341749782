import React, { useState, useEffect } from "react";
/** COMPONENTS */
import {
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Alert,
} from "@mui/material";
import useLocale from "../../../../../utils/locale/locale.hook";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import animationData from "../../../../../assets/img/lottie/loading-setup.json";
/** SERVICES */
import {
  statusPlugin,
  connectPlugin,
  initPlugin,
} from "../../../../../services/setup.service";

const InstallComponent = (props) => {
  const [configuration, setConfiguration] = useState(null);
  const locale = useLocale();
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [error, setError] = useState(null);
  const [initStarted, setInitStarted] = useState(false);
  const [count, setCount] = useState(0);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    updateStatus();
    const interval = setInterval(() => {
      updateStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  let history = useHistory();
  const updateStatus = async () => {
    let response = await statusPlugin({
      hash: props.hash,
      configurationId: props.configuration.ConfigurationId,
    });

    if (response) {
      setConfiguration(response);
      if (
        (response?.Status === "error" ||
          response?.Status === "not_connected") &&
        initStarted
      ) {
        setError(response?.Status);
      } else if (initStarted) setError(null);
    }
  };
  const connect = async () => {
    let response = await connectPlugin({
      configurationId: props.configuration.ConfigurationId,
    });
  };

  const init = async () => {
    setInitStarted(true);
    let response;
    if (props.connect) {
      response = await connectPlugin({
        configurationId: props.configuration.ConfigurationId,
      });
      if (!response.success) {
        setInitStarted(false);
        setError("Could not connect, please check the credentials provided");
        return;
      }
    }

    response = await initPlugin({
      configurationId: props.configuration.ConfigurationId,
    });
    setTimeout(() => {
      setCount(1);
    }, 20000);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {configuration?.Status === "ready" && !initStarted && (
        <Card sx={{ minWidth: 275, marginTop: "20px" }} variant="outlined">
          <CardContent>
            Il semble que l'installation ait déjà été faite, voulez-vous la
            refaire ? Attention, refaire une installation peut interférer avec
            le système déjà mis en place, il est conseillé de ne faire qu'une
            installation.
          </CardContent>
          <CardActions>
            <Button size="small" onClick={init}>
              Refaire l'installation
            </Button>
          </CardActions>
        </Card>
      )}
      {configuration?.Status === undefined && (
        <Card sx={{ minWidth: 275, marginTop: "20px" }} variant="outlined">
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </CardContent>
        </Card>
      )}
      {configuration?.Status !== "ready" &&
        configuration?.Status !== undefined &&
        !initStarted && (
          <Card sx={{ minWidth: 275, marginTop: "20px" }} variant="outlined">
            <CardContent>
              Voulez-vous lancer l'installation du plugin ? Cette étape peut
              durer quelques minutes...
            </CardContent>
            <CardActions>
              <Button size="small" onClick={init}>
                Lancer l'installation
              </Button>
            </CardActions>
          </Card>
        )}
      {(configuration?.Status !== "ready" || count === 0) && initStarted && (
        <Lottie
          options={defaultOptions}
          height={300}
          width={300}
          isStopped={false}
          isPaused={false}
        />
      )}
      {configuration?.Status === "ready" && count === 1 && initStarted && (
        <Alert
          severity="success"
          style={{ margin: 20, padding: 10 }}
          onClose={() => {
            window.location.reload();
          }}
        >
          L'installation s'est bien déroulée
        </Alert>
      )}
      {error && (
        <Alert
          severity="error"
          style={{ margin: 20, padding: 10 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                props.handleBack();
              }}
            >
              Voir
            </Button>
          }
        >
          Une erreur est survenue, veuillez vérifier les informations entrées
          dans le formulaire précédent
        </Alert>
      )}
    </Box>
  );
};

export default InstallComponent;
