import React, { useEffect, useState } from "react";
/** LIB */
/** COMPONENTS */
import { Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
import { Button, Row, Col } from "reactstrap";

import ZeroStepComponent from "./step-0.component";
import InstallComponent from "../common/install.component";

const StepperComponent = ({
  plugins,
  configuration,
  connectorTypes,
  dataTypeFamilies,
  boxes,
  updateConfig,
  hash,
  submitConfiguration,
  activeStep,
  loading,
  loadingMessage,
  handleBack,
  error,
}) => {
  const steps = [
    {
      label: "Configuration",
      next: "Je valide ma configuration",
      nextFunction: () => submitConfiguration(),
      component: (
        <ZeroStepComponent
          plugins={plugins}
          //protocols={protocols}
          dataTypeFamilies={dataTypeFamilies}
          configuration={configuration}
          connectorTypes={connectorTypes}
          boxes={boxes}
          updateConfig={updateConfig}
        />
      ),
    },
    {
      label: "Mise en production",
      next: null,
      nextFunction: () => {},
      component: (
        <InstallComponent
          hash={hash}
          configuration={configuration}
          connect={true}
          handleBack={handleBack}
        />
      ),
    },
  ];
  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps
          ?.map((el) => el.label)
          ?.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
      </Stepper>
      {activeStep === 0 && (
        <ZeroStepComponent
          plugins={plugins}
          //protocols={protocols}
          dataTypeFamilies={dataTypeFamilies}
          configuration={configuration}
          connectorTypes={connectorTypes}
          boxes={boxes}
          updateConfig={updateConfig}
        />
      )}
      {activeStep >= 1 && steps[activeStep].component}
      <Col>
        {error && (
          <div className="text-warning" style={{ marginTop: 5 }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 5 }}
            />
            <span>{error}</span>
          </div>
        )}
      </Col>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div>
          {activeStep > 0 && (
            <Button color="primary" type="button" onClick={handleBack}>
              Précédent
            </Button>
          )}
        </div>
        <div>
          {!loading && steps?.[activeStep].next && (
            <Button
              color={activeStep == steps.length - 1 ? "warning" : "primary"}
              type="button"
              onClick={steps[activeStep].nextFunction}
            >
              {steps[activeStep].next}
            </Button>
          )}

          {loading && (
            <Row style={{ alignItems: "center" }}>
              <span className="text-muted">{loadingMessage}</span>
              <CircularProgress style={{ marginLeft: 20 }} />
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default StepperComponent;
