import { post, get } from "./utils.service";

export const signin = async (email, password, fingerPrint, code) => {
    const res = await post(`/auth/signin`, {
        email,
        password,
        fingerPrint,
        code,
    });
    return await res.json();
};


export const logout = async () => {
    localStorage.removeItem("token");
    document.cookie = "token=; Max-Age=-99999999; Domain=a-grid.com; path=/";
    document.cookie = "token=; Max-Age=-99999999; Domain=a-grid.com; path=/auth";
    document.cookie = "token=; Max-Age=-99999999; Domain=a-grid.com; path=/admin";
    document.cookie = "token=; Max-Age=-99999999; Domain=a-grid.com; path=*";
    setTimeout(() => {
        window.location.href = "/auth/login";
    }, 300);
};
export const getRefreshToken = async () => {
    const res = await get(`/auth/refreshToken`);
    const res_json = await res.json();
    if (res_json && res_json.token) {
        if (res_json.token.split(".").length == 3) {
            localStorage.setItem("token", res_json.token);
        }
    }
};
