import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
    name: "auth",
    initialState: {
        token: null,
        role: null
    },
    reducers: {
        setToken: (state, { payload }) => {
            return {
                token: payload
            }
        },
        resetToken: () => ({
            token: null
        }),
        setRole: (state, { payload }) => {
            return {
                role: payload
            }
        },
    }
});

export default AuthSlice;