import React from "react";
/** COMPONENTS */
import { Card, CardBody } from "reactstrap";
import { Backdrop } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const WarningComponent = ({ children }) => {
    return (
        <Backdrop open={true} style={{ zIndex: 1000 }}>
            <Card>
                <CardBody>
                    <WarningIcon style={{ margin: 10 }} />
                    {children}
                </CardBody>
            </Card>
        </Backdrop>
    );
};

export default WarningComponent;
