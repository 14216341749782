import React, { useState, useEffect } from "react";
/** LIB */
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
/** COMPONENTS */
import {  ModalBody, Container, Modal } from "reactstrap";
import {
    Backdrop,
    CircularProgress,
    Alert,
    Button,
} from "@mui/material";
import ModalComponent from "./components/modal.component";
import TableComponent from "./components/table.component";
import SettingsModalComponent from "./components/edit-modal/modal.component";
import { sleep } from "../../utils/global.utils";
import Layout from "../../components/Global/layout.component";
/** SERVICES */
import {
    getExistingConfigurations,
    deleteConfiguration,
} from "../../services/setup.service";

const SetupPage = (props) => {
    const [modal, setModal] = useState(false);
    const [sites, setSites] = useState([]);
    const [site, setSite] = useState(null);
    const [editSite, setEditSite] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [timeStamp, setTimeStamp] = useState(0);
    const selectedSite = useSelector((state) => state.designStore.selectedSite);
    const [showBackdrop, setShowBackdrop] = useState(false);

    useEffect(() => {
        if (selectedSite) {
            init();
        }
    }, [selectedSite]);

    const init = () => {
        console.log("selectedSite", selectedSite);
        getDetails(selectedSite);
        setSites([selectedSite]);
    };

    const getDetails = async (row) => {
        let res = await getExistingConfigurations(row.Hash);
        return res.configurations || [];
    };

    const deleteConfig = (id) => {
        setShowAlert({
            warning: true,
            title: "Attention",
            content:
                "Si vous décidez d'effacer la configuration, les données seront effacées",
            confirmBtnText: "Je confirme",
            cancelBtnText: "Annuler",
            showCancel: true,
            onConfirm: () => {
                confirmDeleteConfiguration(id);
            },
            onCancel: () => {},
        });
    };

    const confirmDeleteConfiguration = async (id) => {
        setShowBackdrop(true);
        let res = await deleteConfiguration(id);
        await sleep(1000);
        setShowBackdrop(true);
        if (res.success) {
            window.location.reload();
        } else {
            alert("une erreur est survenue");
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeStamp(timeStamp + 1);
        }, 1000);
        return timer;
    });

    return (
        <Layout title="Installation">
            <Container style={{ padding: 0, paddingTop: 20, position: "relative" }}>
                {showBackdrop && (
                    <Backdrop
                        open={true}
                        style={{ color: "#fff", zIndex: 1000 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                {editSite && site && (
                    <SettingsModalComponent
                        modal={editSite}
                        setModal={setEditSite}
                        site={site}
                        setShowAlert={() => {}}
                    />
                )}
                {loading && (
                    <Modal isOpen={loading}>
                    <ModalBody
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div>
                            <span>Deleting</span>
                            {timeStamp % 3 === 0 && <span>.</span>}
                            {timeStamp % 3 === 1 && <span>..</span>}
                            {timeStamp % 3 === 2 && <span>...</span>}
                        </div>
                        <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={40}
                            width={40}
                        />
                    </ModalBody>
                </Modal>
                )}
                {showAlert && (
                    <Alert
                        severity={showAlert.warning ? "error" : "success"}
                        onClose={() => {
                            setShowAlert(null);
                            showAlert.onCancel();
                        }}
                        action={
                            !showAlert.confirmBtnText ? null : (
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setShowAlert(null);
                                        showAlert.onConfirm();
                                    }}
                                >
                                    {showAlert.cancelBtnText}
                                </Button>
                            )
                        }
                    >
                        {showAlert.content}
                    </Alert>
                )}

                <TableComponent
                    headers={{
                        Name: "Nom du site",
                        Address: "Adresse",
                        City: "Ville",
                        Edit: "",
                    }}
                    // rows={sites}
                    selectedSite={selectedSite}
                    edit={(el) => {
                        setSite(el);
                        setConfiguration(null);
                        setModal(true);
                    }}
                    delete={(el) => deleteConfig(el.ConfigurationId)}
                    detailsHeader={{
                        ConfigurationId: "Id",
                        PluginName: "Plugin",
                        ProtocolName: "Protocole",
                        Address: "Adresse du bâtiment",
                        Edit: "",
                    }}
                    getDetails={getDetails}
                    detailEdit={(el, detail) => {
                        setSite(el);
                        setConfiguration(detail);
                        setModal(true);
                    }}
                    editSite={(el) => {
                        setEditSite(true);
                        setSite(el);
                    }}
                    setShowBackdrop={setShowBackdrop}
                    modal={modal}
                />

                {site && modal && (
                    <ModalComponent
                        modal={modal}
                        setModal={setModal}
                        site={site}
                        configuration={configuration}
                        setShowAlert={setShowAlert}
                    />
                )}
            </Container>
        </Layout>
    );
};


export default SetupPage;
