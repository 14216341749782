const env = {
    googleApiKey: "6Lcr3j8aAAAAAEbndNWFLdMvmsIW7Zs9pgoIKUoc",
};

const prod = {
    ...env,
    apiUrl: "https://api.a-grid.com/api",
};

const dev = {
    ...env,
    apiUrl: `${process.env.REACT_APP_HOST_IP}:3005/api`,
    //apiUrl: "https://api.a-grid.com/api",
};

// export const environment = staging;
//export const environment = dev;

// export const environment = process.env.ENV === "DEV" ? dev : prod;
export const environment = process.env.NODE_ENV === "development" ? dev : prod;
// export const environment =
//     process.env.ENV === "PROD"
//         ? prod
//         : location && location.hostname.includes("staging")
//         ? staging
//         : dev;
