import { deviceDetect } from "react-device-detect";
import React, { useState, useEffect } from "react";
import * as hash from "object-hash";
import { validateEmail } from "../../utils/regex.utils";
import { signin } from "../../services/auth.service";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Logo from "../../assets/img/brand/logo_black.png";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Button from "@mui/material/Button";

import uuid from "react-uuid";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([]);
  const [fingerPrint, setFingerPrint] = useState(null);
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState(null);
  useEffect(() => {
    let key = uuid();
    let device_cookie = localStorage.getItem("device_cookie");
    if (!device_cookie) {
      localStorage.setItem("device_cookie", key);
      device_cookie = key;
    }
    let data = deviceDetect();
    data = { ...data, device_cookie };

    var h = hash({ ...data });
    setFingerPrint(h);
  }, []);
  const verifyInputs = () => {
    let errors = [];
    if (!validateEmail(email)) errors.push("email");
    if (!email.includes("admin"))
      window.location.href = "https://dashboard.a-grid.com";
    if (!password) errors.push("password");
    setErrors(errors);
    return errors.length > 0;
  };
  const submit = async () => {
    let hasErrors = verifyInputs();
    if (!hasErrors) {
      setLoading(true);
      setError("");
      let res = await signin(email, password, fingerPrint, code);
      if (res && res.jwt) {
        localStorage.setItem("token", res.jwt);
        window.location.href = "/";
      } else {
        if (res?.toggleCode) {
          setError(
            "New device. An email has been sent to the e-mail address. The code will be valid for 10 minutes."
          );
          setShowCode(true);
        } else {
          setError(
            "An error occured. Be sure that you username/password is correct."
          );
        }
        setLoading(false);
      }
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {loading && (
        <Backdrop open={true} style={{ color: "#fff", zIndex: 1000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <img
        src={Logo}
        alt="agrid_log"
        style={{
          width: 120,
          objectFit: "contain",
          position: "fixed",
          top: 20,
          left: 20,
        }}
      />
      <Card style={{ padding: 30, width: 350 }}>
        <CardContent>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              id="email-with-icon-textfield"
              label="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="email"
              onFocus={() => setFocusedEmail(true)}
              onBlur={() => setFocusedEmail(false)}
              onChange={(e) => setEmail(e.target.value)}
              style={{ margin: 5 }}
            />
            <TextField
              id="password-with-icon-textfield"
              label="Mot de passe"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="password"
              onFocus={() => setFocusedPassword(true)}
              onBlur={() => setFocusedPassword(false)}
              onChange={(e) => setPassword(e.target.value)}
              style={{ margin: 5 }}
            />
            {showCode && (
              <TextField
                id="input-with-icon-textfield"
                label="Code (4 chiffres)"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkEmailReadIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Code (4 chiffres)"
                type="number"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                style={{ margin: 5 }}
              />
            )}
          </div>
          <div className="text-center">
            <Button
              variant="contained"
              onClick={submit}
              style={{
                cursor: "pointer",
                marginTop: 20,
                width: "100%",
              }}
              disabled={loading}
            >
              Connexion
            </Button>
          </div>
          {error && <p style={{ fontSize: 12, color: "red" }}>{error}</p>}
          <div style={{ marginTop: 20, fontSize: 11 }}>
            Mot de passe oublié ?{" "}
            <a
              href="mailto:contact@a-grid.com"
              style={{
                color: "rgb(66, 133, 244)",
                textDecoration: "none",
              }}
            >
              Contactez-nous
            </a>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
