import { configureStore } from "@reduxjs/toolkit";
import MeSlice from "./slices/me.slice";
import AuthSlice from "./slices/auth.slice";
import GlobalSlice from "./slices/global.slice";
import ConsumptionUnitsSlice from "./slices/consumptionUnits.slice";
import DashboardSlice from "./slices/dashboard.slice";
import DesignSlice from "./slices/design.slice";
import SiteSlice from "./slices/site.slice";
import LocaleSlice from "./slices/locale.slice";
import MaterialsSlice from "./slices/materials.slice";

export const store = configureStore({
    reducer: {
        meStore: MeSlice.reducer,
        authStore: AuthSlice.reducer,
        globalStore: GlobalSlice.reducer,
        consumptionUnitsStore: ConsumptionUnitsSlice.reducer,
        dashboardStore: DashboardSlice.reducer,
        designStore: DesignSlice.reducer,
        siteStore: SiteSlice.reducer,
        localeStore: LocaleSlice.reducer,
        materialsStore: MaterialsSlice.reducer,
    },
});
export type RootState = ReturnType<typeof store.getState>;
