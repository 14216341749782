import React, { useEffect } from "react";
/** COMPONENTS */
import { makeStyles } from "@mui/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function SpanningTable(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell>Cible</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.selections.length > 0 &&
                        props.selections.map((row, index) => (
                            <TableRow
                                key={index}
                                style={{ boxSizing: "border-box" }}
                            >
                                <TableCell>
                                    {row[0].map((el) => (
                                        <div>{el.title}</div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: "flex" }}>
                                        {row[1].map((el) => (
                                            <div
                                                style={{
                                                    padding: 3,
                                                    borderRadius: 2,
                                                    background: "#e0e0e0",
                                                    marginRight: 3,
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                {el.title}
                                            </div>
                                        ))}
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    <i
                                        className="fa fa-trash"
                                        style={{
                                            padding: 2,
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            props.removeSelection(index)
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
