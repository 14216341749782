import React, { useEffect, useState } from "react";
/** COMPONENTS */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import GoogleAutocomplete from "../../../../components/Global/google.autocomplete.component";
import ManagerFormComponent from "./manager.form";

const BuildingFormComponent = (props) => {
    const [buildings, setBuildings] = useState(props.buildings || []);
    useEffect(() => {
        if (props.buildings) {
            let newBuildings = [...props.buildings];
            for (let i = 0; i < newBuildings.length; i++) {
                let building = newBuildings[i];
                building.workers = building.workers || props.workers;
            }
            console.log("newBuildings", newBuildings);
            setBuildings(newBuildings);
        }
    }, [props.buildings, props.workers]);

    const changeBuilding = (index, key, value) => {
        let newBuildings = [...buildings];
        let building = buildings[index];
        building[key] = value;
        props.setBuildings(newBuildings);
    };

    const changeAddress = (index, val) => {
        let newBuildings = [...buildings];
        let building = newBuildings[index];
        console.log("val", val);
        building["Address"] = val.Address;
        building["Latitude"] = val.Latitude;
        building["Longitude"] = val.Longitude;
        building["ZipCode"] = val.ZipCode;
        building["City"] = val.City;
        console.log("newBuildings", newBuildings);
        props.setBuildings(newBuildings);
    };

    return (
        <>
            {buildings.length > 0 &&
                buildings.map((el, index) => {
                    return (
                        <Card>
                            <CardHeader>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>Batiment #{index + 1}</span>
                                    <i
                                        className="fa fa-times"
                                        onClick={() =>
                                            props.removeBuilding(index)
                                        }
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form className="needs-validation" noValidate>
                                    <div className="form-row">
                                        <Col className="mb-16" md="6">
                                            <label
                                                className="form-control-label"
                                                htmlFor="buildingName"
                                            >
                                                Nom
                                            </label>
                                            <Input
                                                value={el.Name}
                                                placeholder="Nom"
                                                type="text"
                                                onChange={(e) =>
                                                    changeBuilding(
                                                        index,
                                                        "Name",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col className="mb-16" md="6">
                                            <label
                                                className="form-control-label"
                                                htmlFor="MeterNumber"
                                            >
                                                Numéro de compteur électrique
                                            </label>
                                            <Input
                                                value={el.MeterNumber}
                                                placeholder="Compteur"
                                                type="text"
                                                onChange={(e) =>
                                                    changeBuilding(
                                                        index,
                                                        "MeterNumber",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                    </div>
                                    <div className="form-row">
                                        <Col className="mb-6" md="6">
                                            <label
                                                className="form-control-label"
                                                htmlFor="validationCustom02"
                                            >
                                                Adresse
                                            </label>
                                            <GoogleAutocomplete
                                                defaultInputValue={el.Address}
                                                onChange={(val) =>
                                                    changeAddress(index, val)
                                                }
                                            />
                                        </Col>
                                        <Col className="mb-6" md="6">
                                            <label
                                                className="form-control-label"
                                                htmlFor="validationCustom02"
                                            >
                                                Surface
                                            </label>
                                            <InputGroup>
                                                <Input
                                                    value={el.Surface}
                                                    placeholder="Surface"
                                                    type="number"
                                                    onChange={(e) =>
                                                        changeBuilding(
                                                            index,
                                                            "Surface",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText
                                                        style={{
                                                            background:
                                                                "#e9ecef",
                                                            color: "#495057",
                                                        }}
                                                    >
                                                        m2
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </div>
                                </Form>
                                <ManagerFormComponent
                                    workers={props.workers}
                                    errors={props.errors}
                                    setWorkers={(newData) => {
                                        changeBuilding(
                                            index,
                                            "workers",
                                            newData
                                        );
                                    }}
                                    index={index + 1}
                                />
                            </CardBody>
                        </Card>
                    );
                })}
            <Button onClick={() => props.addBuilding()} color="primary">
                Ajouter un bâtiment
            </Button>
        </>
    );
};

export default BuildingFormComponent;
