import React from "react";
/** COMPONENTS */
import { Input, Col, Row } from "reactstrap";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useLocale from "../../../../../utils/locale/locale.hook";

const ZeroStepComponent = (props) => {
  const locale = useLocale();
  return (
    <Grid container spacing={2} style={{padding: 10}}>
      <Grid item xs={2} sm={6} md={6}>
        <FormControl fullWidth>
          <InputLabel style={{ background: "white" }}>Plugin</InputLabel>
          <Select
            type="select"
            onChange={(e) => {
              props.updateConfig("PluginId", e.target.value);
            }}
            value={props.configuration.PluginId}
          >
            {props.plugins.map((el) => {
              return (
                <MenuItem
                  value={el.PluginId}
                >{`${el.Name}-${el.Technical_Name}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} sm={6} md={6}>
        <FormControl fullWidth>
          <InputLabel style={{ background: "white" }}>Connecteur</InputLabel>
          <Select
            type="select"
            onChange={(e) => {
              props.updateConfig("ConnectorTypeId", e.target.value);
            }}
            value={props.configuration.ConnectorTypeId}
          >
            {props.connectorTypes
              .filter((el) => el.PluginId == props.configuration.PluginId)
              .map((el, index) => {
                return (
                  <MenuItem value={el.ConnectorTypeId}>
                    {el.Name}-{el.Model}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} sm={6} md={6}>
        <TextField
          style={{ width: "100%" }}
          label="Gateway (adresse IP locale)"
          type="text"
          onChange={(e) => {
            props.updateConfig("Gateway", e.target.value);
          }}
          id="gateway"
          value={props.configuration.Gateway}
          placeholder="192.100.2.1"
          error={props?.errors?.find((el) => el?.key === "Gateway") ? 1 : 0}
        />
      </Grid>
      <Grid item xs={2} sm={6} md={6}>
        <TextField
          style={{ width: "100%" }}
          error={props?.errors?.find((el) => el?.key === "Port") ? 1 : 0}
          label="Port"
          type="text"
          onChange={(e) => {
            props.updateConfig("Port", e.target.value);
          }}
          id="port"
          value={props.configuration.Port}
          placeholder="80"
        />
      </Grid>

      <Grid item xs={2} sm={6} md={6}>
        <TextField
          style={{ width: "100%" }}
          error={props?.errors?.find((el) => el?.key === "Username") ? 1 : 0}
          label="Nom d'utilisateur"
          type="text"
          onChange={(e) => {
            props.updateConfig("Username", e.target.value);
          }}
          id="username"
          value={props.configuration.Username}
          placeholder="admin"
        />
      </Grid>
      <Grid item xs={2} sm={6} md={6}>
        <TextField
          style={{ width: "100%" }}
          label="Mot de passe"
          type="text"
          error={props?.errors?.find((el) => el?.key === "Password") ? 1 : 0}
          onChange={(e) => {
            props.updateConfig("Password", e.target.value);
          }}
          id="password"
          value={props.configuration.Password}
          placeholder="daikin"
        />
      </Grid>
    </Grid>
  );
};

export default ZeroStepComponent;
