import { createSlice } from "@reduxjs/toolkit";

const ConsumptionUnitsSlice = createSlice({
    name: "consumptionUnits",
    initialState: {
        // TOTAL: true,
        // HEATER: true,
        // THERMAL: true,
        // POWER_ELEC_CLIM: true,
        // HOT_WATER: true,
    },
    reducers: {
        setConsumptionUnits: (state, { payload }) => {
            return payload;
        },
        updateConsumptionUnits: (state, { payload }) => {
            console.log("payload", payload);
            return {
                ...state,
                ...payload,
            };
        },
    },
});

export default ConsumptionUnitsSlice;
