import React from "react";
/** COMPONENTS */
import { Input, Col } from "reactstrap";
import useLocale from "../../../../../utils/locale/locale.hook";

const ZeroStepComponent = (props) => {
    const locale = useLocale();
    return (
        <Col>
            <label className="form-control-label" htmlFor="plugins">
                Plugins
            </label>

            <Input
                type="select"
                onChange={(e) => {
                    props.updateConfig("PluginId", e.target.value);
                }}
                value={props.configuration.PluginId}
                id="plugins"
            >
                {props.plugins.map((el) => {
                    return (
                        <option
                            value={el.PluginId}
                        >{`${el.Name}-${el.Technical_Name}`}</option>
                    );
                })}
            </Input>
            {/* <label className="form-control-label" htmlFor="dataTypeFamilies">
                Familles
            </label> */}

            {/* <Input
                type="select"
                onChange={(e) => {
                    props.updateConfig("DataTypeFamilyId", e.target.value);
                }}
                id="dataTypeFamilies"
                value={props.configuration.DataTypeFamilyId}
            >
                {props.dataTypeFamilies.map((el) => {
                    return (
                        <option value={el.DataTypeFamilyId}>
                            {locale["dataFamilyTypes"][el.Name]}
                        </option>
                    );
                })}
            </Input> */}
            <label className="form-control-label" htmlFor="connectorTypes">
                Connecteur
            </label>
            <Input
                type="select"
                onChange={(e) => {
                    console.log("changed config", e);
                    props.updateConfig("ConnectorTypeId", e.target.value);
                }}
                id="connectorTypes"
                value={props.configuration.ConnectorTypeId}
            >
                {props.connectorTypes
                    .filter((el) => el.PluginId == props.configuration.PluginId)
                    .map((el, index) => {
                        return (
                            <option value={el.ConnectorTypeId}>
                                {el.Name}-{el.Model}
                            </option>
                        );
                    })}
            </Input>
            <label className="form-control-label" htmlFor="ae200">
                Adresse IP de l'AE-200 sur le réseau local
            </label>

            <Input
                type="text"
                onChange={(e) => {
                    props.updateConfig("Gateway", e.target.value);
                }}
                id="ae200"
                value={props.configuration.Gateway}
            />
        </Col>
    );
};

export default ZeroStepComponent;
