import { createSlice } from "@reduxjs/toolkit";

const SiteSlice = createSlice({
    name: "dashboard",
    initialState: {
        mode: "Energy",
        site: {},
    },
    reducers: {
        setSite: (state, { payload }) => {
            return payload;
        },
        updateSite: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
    },
});

export default SiteSlice;
