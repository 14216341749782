import { createSlice } from "@reduxjs/toolkit";

let initialState: {
    language: null,
    keys: {},
};

const LocaleSlice = createSlice({
    name: "locale",
    initialState: {
        ...initialState,
    },
    reducers: {
        setKeys: (state, { payload }) => ({
            ...state,
            keys: payload,
        }),
        setLanguage: (state, { payload }) => ({
            ...state,
            language: payload,
        }),
        resetLocale: () => initialState,
    },
});

export default LocaleSlice;
