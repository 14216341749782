import React, { useEffect, useState } from "react";
/** LIBS */
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
/** COMPONENTS */
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Button,
  Tooltip,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import BoxStatusComponent from "./box-status.component";
import DefaultPic from "../../../assets/img/site.png";
/** SERVICES */
import { generateZip } from "../../../services/site.service";

function Row(props) {
  const [row, setRow] = useState(props.row);
  const [open, setOpen] = React.useState(true);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetchDetails();
  }, [props.row]);

  const fetchDetails = async () => {
    let newDetails = await props.getDetails(row);
    console.log("newDetails", newDetails);
    if (newDetails) setDetails(newDetails);
  };

  const getBoxZip = async (hash) => {
    console.log("getZip");
    props.setShowBackdrop(true);
    let res = await generateZip(hash);
    if (res && res?.output?.success) {
      console.log("res", res);
      var zip = new JSZip();
      for (let i = 0; i < Object.keys(res.output).length; i++) {
        if (Object.keys(res.output)[i] !== "success") {
          zip.file(
            Object.keys(res.output)[i],
            res.output[Object.keys(res.output)[i]]
          );
        }
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, "agrid-starter.zip");
      });
    } else {
      alert(res?.output?.errorMessage);
      //setShowAlert(false);
    }
    props.setShowBackdrop(false);
  };

  const onStatusReceive = (boxId, status) => {
    let newBoxes = row.boxes.map((el) => {
      if (el?.BoxId != boxId) return el;
      else return { ...el, status };
    });
    let newRow = { ...row };
    newRow.boxes = newBoxes;
    setRow(newRow);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer component={Paper} style={{ margin: 5, padding: 3 }}>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <span style={{ display: "flex" }}>
                      Mes Boxes
                      <Tooltip
                        title="Une boxe permet de se connecter à votre réseau"
                        enterDelay={200}
                        leaveDelay={200}
                        style={{
                          fontSize: 12,
                          color: "#e0e0e0",
                        }}
                      >
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </span>
                  </div>
                </Typography>
                {row.boxes.length > 0 && (
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      {row.boxes.map((box) => {
                        return (
                          <TableRow>
                            <TableCell
                              style={{
                                display: "flex",
                              }}
                            >
                              {!props.modal && (
                                <BoxStatusComponent
                                  hash={box.Hash}
                                  onStatusReceive={(status) =>
                                    onStatusReceive(box?.BoxId, status)
                                  }
                                />
                              )}
                              <span>{`${box.Name}`}</span>

                              <span
                                style={{
                                  color: "#9e9e9e",
                                }}
                              >
                                {`/ ${box.BridgeName}`}
                              </span>
                            </TableCell>

                            <TableCell align="right">
                              {box.BridgeName == "agrid_box" ||
                              box.BridgeName == "agrid_virtual" ? (
                                <Button
                                  size="small"
                                  onClick={() => getBoxZip(box.Hash)}
                                  color="primary"
                                  // disabled={
                                  //     !(
                                  //         box?.status ===
                                  //         "connected"
                                  //     )
                                  // }
                                >
                                  Télécharger
                                </Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </TableContainer>
            <TableContainer
              component={Paper}
              style={{ margin: 5, marginTop: 10, padding: 3 }}
            >
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <span style={{ display: "flex" }}>
                      Mes plugins
                      <Tooltip
                        title="Un plugin permet de piloter un type de capteurs. Un plugin nécessite une boxe active."
                        enterDelay={200}
                        leaveDelay={200}
                        style={{
                          fontSize: 12,
                          color: "#e0e0e0",
                        }}
                      >
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </span>
                    {props.edit && (
                      <Button
                        size="small"
                        onClick={() => props.edit(row)}
                        color="primary"
                        disabled={
                          row?.boxes?.filter((el) => el?.status == "connected")
                            ?.length === 0
                        }
                      >
                        Ajouter
                      </Button>
                    )}
                  </div>
                </Typography>
                {details.length > 0 && (
                  <Table size="small">
                    <TableBody>
                      {details.map((detail, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "" : "rgba(0, 0, 0, 0.04)",
                            border: "none",
                          }}
                        >
                          {Object.keys(props.detailsHeader).map((el) => {
                            if (el == "Edit") {
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    justifyContent: "flex-end",
                                    display: "flex",
                                  }}
                                >
                                  {props.detailEdit && (
                                    <i
                                      className="fa fa-edit"
                                      onClick={() => {
                                        if (
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length > 0
                                        ) {
                                          props.detailEdit(row, detail);
                                        }
                                      }}
                                      style={{
                                        cursor:
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length === 0
                                            ? "not-allowed"
                                            : "pointer",
                                        margin: 3,
                                        padding: 2,
                                        color:
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length === 0
                                            ? "rgba(0, 0, 0, 0.26)"
                                            : "black",
                                      }}
                                    />
                                  )}
                                  {props.detailDelete && (
                                    <i
                                      className="fa fa-trash"
                                      onClick={() => {
                                        if (
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length > 0
                                        ) {
                                          props.detailDelete(detail);
                                        }
                                      }}
                                      style={{
                                        cursor:
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length === 0
                                            ? "not-allowed"
                                            : "pointer",
                                        color:
                                          row?.boxes?.filter(
                                            (el) => el?.status === "connected"
                                          )?.length === 0
                                            ? "rgba(0, 0, 0, 0.26)"
                                            : "red",
                                        margin: 3,
                                        padding: 2,
                                      }}
                                    />
                                  )}
                                </TableCell>
                              );
                            } else {
                              return <TableCell>{detail[el]}</TableCell>;
                            }
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
      id="setup-container"
    >
      <Table aria-label="collapsible table">
        <TableBody>
          {props.selectedSite && (
            <Row
              key={props.selectedSite.Name}
              row={props.selectedSite}
              columns={Object.keys(props.headers)}
              edit={props.edit}
              detailEdit={props.detailEdit}
              getDetails={props.getDetails}
              detailsHeader={props.detailsHeader}
              detailDelete={props.delete}
              editSite={props.editSite}
              setShowBackdrop={props.setShowBackdrop}
              modal={props.modal}
            />
          )}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
      {/* <Card style={{ margin: 5, width: 350 }} variant="outlined">
           
                {props.selectedSite?.PictureUrl ? (
                    <CardMedia
                        component="img"
                        alt="Site Pic"
                        height="140"
                        image={props.selectedSite?.PictureUrl}
                        title="Site picture"
                    />
                ) : (
                    <CardMedia
                        component="img"
                        alt="Site Pic"
                        height="140"
                        image={DefaultPic}
                        title="Site picture"
                    />
                )}
                <CardContent>
                    <Typography variant="h6">
                        {props.selectedSite?.Name}
                    </Typography>
                    <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                    >
                        {props.selectedSite?.Address}
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "flex-end" }}>
                    <Button
                        size="small"
                        onClick={() => props.editSite(props.selectedSite)}
                        color="primary"
                    >
                        Editer
                    </Button>
                </CardActions>
            </Card>
        */}
    </div>
  );
}
