import { validateEmail, verifyNAF, verifySiret } from "./regex.utils";

export const checkSite = (site) => {
    let errors = [];
    console.log("site", site);
    if (!site.Address) errors.push("Address");
    if (!site.Name) errors.push("Name");
    if (!site?.Siret || !verifySiret(site?.Siret)) errors.push("Siret");
    if (!site?.NAF || !verifyNAF(site?.NAF.toString())) errors.push("NAF");
    if(!site.LegalEntity) errors.push("LegalEntity");
    if(!site.BusinessName) errors.push("BusinessName");
    return errors;
};

export const checkWorkers = (workers) => {
    console.log("workers", workers);
    let errors = [];
    // for (let i = 0; i < workers.length; i++) {
    //     let worker = workers[i];
    //     worker.Email = worker?.Email?.split(" ").join("");
    //     let type = i === 0 ? "installer" : i === 1 ? "maintainer" : "dsi";
    //     if (!worker.City) errors.push(type + "_City");
    //     if (!worker.FirstName) errors.push(type + "_FirstName");
    //     if (!worker.LastName) errors.push(type + "_LastName");
    //     if (!worker.CompanyName) errors.push(type + "_CompanyName");
    //     if (!validateEmail(worker.Email)) errors.push(type + "_Email");
    //     if (!validatePhone(worker.PhoneNumber))
    //         errors.push(type + "_PhoneNumber");
    // }
    return errors;
};

export const checkBuildings = (buildings) => {
    for (let i = 0; i < buildings.length; i++) {
        let building = buildings[i];
        if (!building.Address || !building.Surface)
            return "Merci de remplir tous les champs";
        else {
            let workers = [
                { ...building.installer },
                { ...building.maintainer },
                { ...building.computerSystemMaintainer },
            ];
            let error = checkWorkers(workers);
            if (error) return error;
        }
    }
    return null;
};
