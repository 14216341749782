import { createSlice } from "@reduxjs/toolkit";

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        datasets: [],
    },
    reducers: {
        setDatasets: (state, { payload }) => {
            return payload;
        },
        updateDatasets: (state, { payload }) => {
            console.log("payload", payload);
            return {
                ...state,
                ...payload,
            };
        },
    },
});

export default DashboardSlice;
