import React, { useEffect, useState } from "react";
/** COMPONENTS */
import { Form, Input, Col } from "reactstrap";
import GoogleAutocomplete from "../../../../components/Global/google.autocomplete.component";
import { getTypes } from "../../../../services/site.service";

const SiteFormComponent = (props) => {
    const [types, setTypes] = useState([]);
    const fetchData = async () => {
        let res = await getTypes();
        if (res && res.types) {
            setTypes(res.types);
        }
    };
    useEffect(() => {
        fetchData();
        console.log("badabazinga", props.site);
    }, []);
    return (
        <Form className="needs-validation" noValidate>
            <div className="form-row">
                <h3>Entreprise</h3>
                <Col className="mb-12" md="12">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Raison sociale
                    </label>
                    <Input
                        value={props.site.BusinessName}
                        id="BusinessName"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("BusinessName", e.target.value)
                        }
                        invalid={props.errors.indexOf(`BusinessName`) > -1}
                        placeholder="Entreprise INC"
                    />
                </Col>
                <Col className="mb-4" md="4">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Adresse
                    </label>
                    <GoogleAutocomplete
                        defaultInputValue={props.site.Address}
                        onChange={props.changeAddress}
                    />
                </Col>
                <Col className="mb-4" md="4">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Code postal
                    </label>
                    <Input
                        value={props.site.ZipCode}
                        id="ZipCode"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("ZipCode", e.target.value)
                        }
                        invalid={props.errors.indexOf(`ZipCode`) > -1}
                    />
                </Col>
                <Col
                    className="mb-4"
                    md="4"
                    style={{ marginBottom: "0px !important" }}
                >
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Ville
                    </label>
                    <Input
                        value={props.site.City}
                        id="City"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("City", e.target.value)
                        }
                        invalid={props.errors.indexOf(`City`) > -1}
                    />
                </Col>
                <Col
                    className="mb-4"
                    md="4"
                    style={{ marginBottom: "0px !important" }}
                >
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Code NAF
                    </label>
                    <Input
                        value={props.site.NAF}
                        id="NAF"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("NAF", e.target.value)
                        }
                        invalid={props.errors.indexOf(`NAF`) > -1}
                    />
                </Col>
                <Col
                    className="mb-4"
                    md="4"
                    style={{ marginBottom: "0px !important" }}
                >
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Siret
                    </label>
                    <Input
                        value={props.site.Siret}
                        id="Siret"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("Siret", e.target.value)
                        }
                        invalid={props.errors.indexOf(`Siret`) > -1}
                        placeholder="78467169500103"
                    />
                </Col>

                <Col className="mb-4" md="4">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                    >
                        Type d'entreprise
                    </label>
                    <Input
                        type="select"
                        onChange={(e) => {
                            props.changeSite("LegalEntity", e.target.value);
                        }}
                        value={props.site.LegalEntity}
                    >
                        {["SAS", "SARL", "SA", "SASU", "EURL", "SNC"].map(
                            (el) => (
                                <option value={el}>{el}</option>
                            )
                        )}
                    </Input>
                    {/* <Input
                        value={props.site.LegalEntity}
                        id="LegalEntity"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("LegalEntity", e.target.value)
                        }
                        invalid={props.errors.indexOf(`LegalEntity`) > -1}
                        placeholder="SAS, SARL?,.."
                    /> */}
                </Col>
                <h3>Site</h3>
                <Col className="mb-12" md="12">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                    >
                        Nom du site
                    </label>
                    <Input
                        value={props.site.Name}
                        id="validationCustom01"
                        type="text"
                        onChange={(e) =>
                            props.changeSite("Name", e.target.value)
                        }
                        invalid={props.errors.indexOf(`Name`) > -1}
                    />
                </Col>
                <Col className="mb-12" md="12">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustomUsername"
                    >
                        Type de bâtiment
                    </label>
                    <Input
                        type="select"
                        onChange={(e) => {
                            props.changeSite("Site_Type_Id", e.target.value);
                        }}
                        value={props.site.Site_Type_Id}
                    >
                        {types &&
                            types.map((el) => (
                                <option value={el.Site_Type_Id}>
                                    {el.Type}
                                </option>
                            ))}
                    </Input>
                </Col>
            </div>
        </Form>
    );
};

export default SiteFormComponent;
