import React from "react";
import { Select, MenuItem } from "@mui/material";

const NavSelect = (props) => {
    return (
        <Select
            style={{
                background: "white",
                color: "#8898aa",
                fontSize: ".875rem",
            }}
            labelId={`${props.label}-simple-select-label`}
            id={`${props.label}-simple-select`}
            //label={props.label}
            onChange={(e) => {
                props.onChange(e);
            }}
            value={props.value}
            key={props.key}
        >
            {props.items.map((el) => {
                return <MenuItem value={el.value}>{el.label}</MenuItem>;
            })}
        </Select>
    );
};

export default NavSelect;
