import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import DocumentsForm from "./documents.form";

const DocumentsWrapper = (props) => {
    const [buildings, setBuildings] = useState([]);
    useEffect(() => {
        for (let i = 0; i < props.buildings.length; i++) {
            let building = props.buildings[i];
            if (!building.hasOwnProperty("documents"))
                building["documents"] = [];
        }
        setBuildings(props.buildings);
    }, [props.buildings]);

    const onDrop = (index, key, files) => {
        let newBuildings = [...props.buildings];
        let building = newBuildings[index];
        if (!building.hasOwnProperty("documents")) building["documents"] = [];
        building["documents"] = building["documents"].filter(
            (el) => el.DocumentType != key
        );
        building["documents"] = [...building["documents"], ...files];
        console.log("newBuildings", newBuildings)
        props.setBuildings(newBuildings);
    };
    return (
        <>
            {buildings.length > 0 &&
                buildings.map((el, index) => {
                    return (
                        <Card>
                            <CardHeader>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>Batiment #{index + 1}</span>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="mb-3" md="4">
                                        <DocumentsForm
                                            title="DOE"
                                            onDrop={(files) =>
                                                onDrop(index, "doe", files)
                                            }
                                            DocumentType="doe"
                                            documents={el["documents"].filter(
                                                (doc) =>
                                                    doc.DocumentType == "doe"
                                            )}
                                        />
                                    </Col>
                                    <Col className="mb-3" md="4">
                                        <DocumentsForm
                                            title="Plans d'architecte (pdf, png, jpg)"
                                            onDrop={(files) =>
                                                onDrop(index, "plan", files)
                                            }
                                            DocumentType="plan"
                                            documents={el["documents"].filter(
                                                (doc) =>
                                                    doc.DocumentType == "plan"
                                            )}
                                        />
                                    </Col>
                                    <Col className="mb-3" md="4">
                                        <DocumentsForm
                                            title="Factures de l'an passé (elec, gaz)"
                                            onDrop={(files) =>
                                                onDrop(index, "invoice", files)
                                            }
                                            DocumentType="invoice"
                                            documents={el["documents"].filter(
                                                (doc) =>
                                                    doc.DocumentType ==
                                                    "invoice"
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    );
                })}
        </>
    );
};

export default DocumentsWrapper;
