import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import SetupPage from "../views/setup/setup.page";
// import MasksPage from "../views/masks/masks.page";
// import MaterialsPage from "../views/materials/materials.page";
import LinkPage from "../views/links/links.page";

const AdminNavigator = () => {
    //return <div></div>;
    return (
        <Router>
            <Switch>
                <Route path="/links">
                    <LinkPage />
                </Route>
                {/* <Route path="/masks">
                    <MasksPage />
                </Route>
                <Route path="/materials">
                    <MaterialsPage />
                </Route> */}
                <Route path="/">
                    <SetupPage />
                </Route>
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};

export default AdminNavigator;
