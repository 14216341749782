import React, { useEffect, useState } from "react";
/** LIB */
import Loader from "react-loader-spinner";
/** COMPONENTS */
import { Modal, ModalBody, ModalHeader, Col, Button, Row } from "reactstrap";
import {
    Stepper,
    Step,
    StepButton,
    StepLabel,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { sleep } from "../../../../utils/global.utils";
import SiteFormComponent from "./site.form.component";
import ManagerFormComponent from "./manager.form";
import BuildingFormComponent from "./building.form";
import ElecFormComponent from "./elec.form";
import DocumentsWrapper from "./documents.wrapper";
import {
    checkSite,
    checkWorkers,
} from "../../../../utils/settings.utils";

/** SERVICES */
import {
    getBuildings,
    updateSite,
    createSite,
    electricitySupplierConsent,
} from "../../../../services/site.service";
import { uploadFile } from "../../../../services/s3.service";

const ModalComponent = (props) => {
    const steps = [
        "Site",
        "Prestataires",
        "Bâtiments",
        "Documents",
        "Fournisseur d'électricité",
    ];
    const [site, setSite] = useState(
        props.site
            ? { LegalEntity: props.site.LegalEntity || "SAS", ...props.site }
            : {
                  Site_Type_Id: 1,
                  LegalEntity: "SAS",
              }
    );
    const [buildings, setBuildings] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [workers, setWorkers] = useState([
        { WorkerType: "installer" },
        { WorkerType: "maintainer" },
        { WorkerType: "dsi" },
    ]);
    const [submitting, setSubmitting] = useState(null);
    const [errors, setErrors] = useState([]);

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => {
            setErrors([]);
            let output = prevActiveStep;
            if (prevActiveStep === 0) {
                let error = checkSite(site);
                if (error?.length > 0) {
                    setErrors(error);
                } else {
                    save();
                    output = prevActiveStep + 1;
                }
            } else if (prevActiveStep === 1) {
                let error = checkWorkers(workers);
                if (error?.length > 0) {
                    setErrors(error);
                } else {
                    save();
                    output = prevActiveStep + 1;
                }
            } else if (prevActiveStep === 2) {
                let error = [];
                for (let i = 0; i < buildings.length; i++) {
                    let building = buildings[i];
                    let res = checkWorkers(workers);
                    res = res.map((el) => {
                        return `${el}${i + 1}`;
                    });
                    error = [...error, ...res];
                }
                console.log("error", error);
                if (error?.length > 0) {
                    setErrors(error);
                } else {
                    save();
                    output = prevActiveStep + 1;
                }
            } else {
                save();
                output = prevActiveStep + 1;
            }

            // if (errors.length == 0) {
            //     console.log("errors", errors);
            // }

            console.log("output", output);
            return output;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const changeSite = (key, value) => {
        let newSite = { ...site };
        newSite[key] = value;

        setSite({ ...newSite });
    };

    const changeAddress = (val) => {
        let newSite = { ...site };
        newSite["Address"] = val?.Address || "";
        newSite["Latitude"] = val?.Latitude || "";
        newSite["Longitude"] = val?.Longitude || "";
        newSite["ZipCode"] = val?.ZipCode || "";
        newSite["City"] = val?.City || "";
        console.log("newSite", newSite);
        setSite({ ...newSite });
    };

    const fetchData = async () => {
        if (props.site && props.site.Hash) {
            let res = await getBuildings(props.site.Hash);
            if (res && res.buildings && res.buildings.length > 0) {
                let buildings = res.buildings;
                setBuildings(buildings);
                setWorkers(buildings[0].workers);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const removeBuilding = (index) => {
        let arr = [...buildings];
        arr.splice(index, 1);
        setBuildings(arr);
    };

    const addBuilding = () => {
        let arr = [...buildings];
        arr.push({
            Latitude: null,
            Longitude: null,
            Address: null,
            ZipCode: null,
            City: null,
            Country: null,
        });
        setBuildings(arr);
    };

    const save = async () => {
        let Hash = props.site.Hash;
        if (!Hash) {
            let response = await createSite({ Site_Type_Id: 1, ...site });
            Hash = response.hash;
        }
        console.log("res", site);
        const res = await updateSite({ site, buildings }, Hash);

        return res;
    };

    const submit = async () => {
        console.log("buildings", buildings);
        setSubmitting("Envoi des documents");
        for (let j = 0; j < buildings.length; j++) {
            let documents = [...buildings[j].documents];
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                //doc.Name = doc.name;
                if (!doc.DocumentId) {
                    let path = await uploadFile(doc);
                    doc.Path = path;
                }
            }
        }
        setSubmitting("Mise à jour des informations");
        const res = await save();
        await sleep(1000);
        await electricitySupplierConsent({ ...site }, site?.Hash);
        await sleep(1000);
        setSubmitting(null);
        window.location.reload();
        props.setShowAlert(res.success);
    };

    useEffect(() => {
        checkCompletion();
    }, [workers, buildings, site]);

    const checkCompletion = () => {
        console.log("site", site);
        let completion = {};
        if (site?.Name && site?.Address) {
            completion[0] = true;
            let workerNotEmpty = workers?.find(
                (el) =>
                    el.FirstName || el.LastName || el.CompanyName || el.Email
            );
            if (workerNotEmpty) {
                completion[1] = true;
                let buildingOk = false;
                for (let i = 0; i < buildings.length; i++) {
                    let workerNotEmpty = buildings[i]?.workers?.find(
                        (el) =>
                            el.FirstName ||
                            el.LastName ||
                            el.CompanyName ||
                            el.Email
                    );
                    if (workerNotEmpty) buildingOk = true;
                }
                if (buildingOk) {
                    completion[2] = true;
                    let documentsFind = false;
                    for (let i = 0; i < buildings.length; i++) {
                        if (
                            buildings[i].documents?.filter(
                                (el) =>
                                    el.DocumentType == "doe" ||
                                    el.DocumentType == "invoice" ||
                                    el.DocumentType == "plan"
                            ).length > 0
                        )
                            documentsFind = true;
                    }
                    if (documentsFind) completion[3] = true;
                }
            }
        }
        console.log("completion", completion);
        setCompleted(completion);
    };

    return (
        <>
            <Modal
                isOpen={props.modal}
                //toggle={() => props.setModal(false)}
                size={"lg"}
                //className={className}
            >
                <ModalHeader toggle={() => props.setModal(false)}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {props.site?.Name || "Ajouter un site"}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Col>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            nonLinear
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    {completed[index] ? (
                                        <StepButton
                                            onClick={handleStep(index)}
                                            completed={completed[index]}
                                        >
                                            {label}
                                        </StepButton>
                                    ) : (
                                        <StepLabel>{label}</StepLabel>
                                    )}
                                    {/* <StepLabel>{label}</StepLabel> */}
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <SiteFormComponent
                                site={site}
                                changeSite={changeSite}
                                changeAddress={changeAddress}
                                errors={errors}
                            />
                        )}
                        {activeStep === 1 && (
                            <ManagerFormComponent
                                site={site}
                                workers={workers}
                                setWorkers={setWorkers}
                                errors={errors}
                            />
                        )}
                        {activeStep === 2 && (
                            <BuildingFormComponent
                                site={site}
                                workers={workers}
                                changeSite={changeSite}
                                buildings={buildings}
                                removeBuilding={removeBuilding}
                                addBuilding={addBuilding}
                                setBuildings={setBuildings}
                                errors={errors}
                            />
                        )}
                        {activeStep === 3 && (
                            <DocumentsWrapper
                                buildings={buildings}
                                setBuildings={setBuildings}
                            />
                        )}
                        {activeStep === 4 && (
                            <ElecFormComponent
                                site={site}
                                changeSite={changeSite}
                            />
                        )}

                        {/* <div style={{ height: 10, color: "red" }}>{errors}</div> */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: 30,
                            }}
                        >
                            <div>
                                {activeStep !== 0 && (
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={handleBack}
                                    >
                                        Précédent
                                    </Button>
                                )}
                            </div>
                            <div>
                                {activeStep !== steps.length - 1 && (
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={handleNext}
                                    >
                                        Suivant
                                    </Button>
                                )}
                                {activeStep === steps.length - 1 &&
                                !submitting ? (
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={submit}
                                    >
                                        Enregistrer
                                    </Button>
                                ) : activeStep === steps.length - 1 ? (
                                    <Row>
                                        {submitting}{" "}
                                        <Loader
                                            type="Rings"
                                            color="#00BFFF"
                                            height={20}
                                            width={20}
                                        />
                                    </Row>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </ModalBody>
            </Modal>
            {submitting && (
                <Backdrop
                    open={true}
                    style={{ zIndex: 10000000, color: "white" }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
};

export default ModalComponent;
