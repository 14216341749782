export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = (phoneNumber) => {
  var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  return re.test(phoneNumber);
};

export function validDaily(url) {
  var p = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
  return url.match(p) ? RegExp.$2 : false;
}

export function validYT(url) {
  var p =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/;
  return url.match(p) ? RegExp.$1 : false;
}

export const containSpecialCharacter = (value) => {
  return value.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
};

export const containLowercaseCharacter = (value) => {
  return value.match(/[a-z]/);
};

export const containUppercaseCharacter = (value) => {
  return value.match(/[A-Z]/);
};

export const containNumber = (value) => {
  return value.match(/[0-9]/);
};

export const validWebsite = (value) => {
  return value.match(
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
};

export const passwordStrength = (password) => {
  if (password.length < 8) return "weak";
  var hasUpperCase = /[A-Z]/.test(password);
  var hasLowerCase = /[a-z]/.test(password);
  var hasNumbers = /\d/.test(password);
  var hasNonalphas = /\W/.test(password);
  if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3)
    return "medium";
  else return "strong";
};

export const verifySiret = (siret) => {
  var isValid;
  if (siret.length != 14 || isNaN(siret)) isValid = false;
  else {
    // Donc le SIRET est un numérique à 14 chiffres
    // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
    // correspondent au numéro d'établissement
    // et enfin le dernier chiffre est une clef de LUHN.
    var somme = 0;
    var tmp;
    for (var cpt = 0; cpt < siret.length; cpt++) {
      if (cpt % 2 == 0) {
        // Les positions impaires : 1er, 3è, 5è, etc...
        tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
        if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
      } else tmp = siret.charAt(cpt);
      somme += parseInt(tmp);
    }
    if (somme % 10 == 0) isValid = true;
    // Si la somme est un multiple de 10 alors le SIRET est valide
    else isValid = false;
  }
  return isValid;
};

export const verifyNAF = (value) => {
  return value.match(/^\d{4}[a-zA-Z]$/);
};

export const verifyIp = (value) => {
  if (!value) return false;
  return value.match(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  );
};
