import React, { useEffect, useState, useRef } from "react";
/** COMPONENTS */
import { Checkbox, FormControlLabel, Button } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const ElecFormComponent = ({ site, changeSite }) => {
    const [supplier, setSupplier] = useState("Enedis");
    const [consent, setConsent] = useState(false);

    const sigCanvas = useRef({});
    const sigPad = useRef({});
    const [signature, setSignature] = useState(null);

    const legal_notices = {
        EDF: "Le Client accepte expressément que ses données personnelles soient conservées par les Tiers et/ou EDF SEI à des fins de gestion et de traçabilité. Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès des Tiers et/ou d'EDF SEI à l'adressemesdonnees-corse@edf.froumesdonnees-reunion@edf.frou mesdonnees-guadeloupe@edf.frou mesdonnees-martinique@edf.frou mesdonnees-guyane@edf.fr. Si besoin, le Client peut solliciter le Délégué à la Protection des Données d'EDF par courriel à l'adresse informatique-et-libertes@edf.fr. Le Client peut également saisir la CNIL",
        Enedis: "Le Client accepte expressément que ses données personnelles soient conservées par le Tiers et/ou Enedis à des fins de gestionet de traçabilité. Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Clientdispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès du Tiers et/ou d’Enedis, 34 place des Corolles, 92079 Paris La DéfenseCedex",
    };
    const erase = () => {
        const dataURL = sigCanvas.current.clear();
        changeSite("Signature", null);
        setConsent(false);
        setSignature(null);
    };
    const setSignatureOnChange = () => {
        const dataURL = sigCanvas.current.toDataURL();
        changeSite("Signature", dataURL);
        setSignature(dataURL);
    };
    useEffect(() => {
        if (site?.ZipCode / 1000 == 20) setSupplier("EDF");
        if (site?.Signature) {
            setConsent(true);
            changeSite("Consent", true);
            sigCanvas.current.fromDataURL(site?.Signature, {
                ratio: 1,
                width: 500,
            });
            setSignature(site?.Signature);
        } else {
        }
    }, []);
    return (
        <>
            <div>
                Pour un fonctionnement optimal, nous avons besoin d'accéder aux
                données énergétiques via la plateforme {supplier}; votre
                signature nous autorise à accéder à:
            </div>
            <ul>
                <li>
                    L’historique des consommations, en kWh, du site (et
                    puissances atteintes et dépassements de puissance)
                </li>
                <li>
                    L’historique des relevés d’index quotidiens, en kWh, et la
                    puissance maximale quotidienne, en kVA ou kWh, du site
                </li>
                <li>L’historique de courbe de charge du site</li>
                <li>
                    Les données techniques et contractuelles disponibles du site
                </li>
            </ul>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={consent}
                        onChange={() => {
                            changeSite("Consent", !consent);
                            setConsent(!consent);
                        }}
                        name="consent"
                        color="primary"
                    />
                }
                label="J'accepte qu'Agrid utilise mes données de consommation pour 60 mois à des fins de recherche et d'optimisation"
            />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SignatureCanvas
                    penColor="#424242"
                    canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas",
                    }}
                    ref={sigCanvas}
                    style={{ border: "1px solid black" }}
                    onEnd={setSignatureOnChange}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <span style={{ fontSize: 10, marginTop: -80, marginRight: 40 }}>
                    Signature
                </span>
                <Button onClick={erase} color="primary">
                    Effacer
                </Button>
            </div>
            <div style={{ fontSize: 10 }}>{legal_notices[supplier]}</div>
            <div></div>
        </>
    );
};

export default ElecFormComponent;
