import React, { useEffect, useState } from "react";
/** COMPONENTS */
import { Checkbox, FormControlLabel } from "@mui/material";
import { Card, CardHeader, CardBody, Form, Input, Col } from "reactstrap";

const ManagerFormComponent = ({ errors, index, workers, setWorkers }) => {
    const [checked, setChecked] = useState(false);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        let worker_installer = workers.find(
            (el) => el.WorkerType === "installer"
        );
        let worker_maintainer = workers.find(
            (el) => el.WorkerType === "maintainer"
        );
        let worker_dsi = workers.find((el) => el.WorkerType === "dsi");
        console.log("workers", workers);
        setCards([
            {
                title: "Coordonnées de l'installateur CVC",
                data: worker_installer,
                action: setWorkers,
                type: "installer",
            },
            {
                title: "Coordonnées du mainteneur CVC",
                data: worker_maintainer,
                action: setWorkers,
                type: "maintainer",
                copy: true,
                copyLabel: "L'installeur est mon mainteneur",
            },
            {
                title: "Coordonnées de la DSI",
                data: worker_dsi,
                action: setWorkers,
                type: "dsi",
            },
        ]);
    }, [workers]);

    const onChange = (key, e, data, action) => {
        let newWorkers = [...workers];
        let newData =
            newWorkers.find((el) => el.WorkerType === data?.WorkerType) || {};
        newData[key] = e.target.value;
        newWorkers[data?.WorkerType] = newData;
        action(newWorkers);
    };

    const handleChangeCheck = () => {
        if (!checked) {
            let newWorkers = [...workers];
            let worker_installer = newWorkers.find(
                (el) => el.WorkerType === "installer"
            );
            newWorkers = newWorkers.map((el) => {
                if (el.WorkerType === "maintainer")
                    return { ...worker_installer, WorkerType: "maintainer" };
                else return el;
            });
            console.log("newWorkers", newWorkers);
            setWorkers(newWorkers);
        }
        setChecked(!checked);
    };
    return (
        <>
            {cards.map((el, elIndex) => {
                return (
                    <Card>
                        <CardHeader
                            style={{
                                justifyContent: "space-between",
                                display: "flex",
                            }}
                        >
                            <div>{el.title} </div>
                            <div>
                                {el.copy && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleChangeCheck}
                                                name="copy"
                                            />
                                        }
                                        label={el.copyLabel}
                                        labelPlacement="start"
                                    />
                                )}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form className="needs-validation" noValidate>
                                <div className="form-row">
                                    {[
                                        {
                                            name: "FirstName",
                                            type: "text",
                                            label: "Prénom",
                                        },
                                        {
                                            name: "LastName",
                                            type: "text",
                                            label: "Nom",
                                        },
                                        {
                                            name: "Email",
                                            type: "text",
                                            label: "Email",
                                        },
                                        {
                                            name: "CompanyName",
                                            type: "text",
                                            label: "Entreprise",
                                        },
                                        {
                                            name: "PhoneNumber",
                                            type: "text",
                                            label: "Téléphone",
                                        },
                                        ,
                                        {
                                            name: "City",
                                            type: "text",
                                            label: "Ville",
                                        },
                                    ].map((field) => {
                                        return (
                                            <Col className="mb-4" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor={`${field.name}-${elIndex}`}
                                                >
                                                    {field.label}
                                                </label>
                                                <Input
                                                    value={
                                                        el.data?.[field.name] ||
                                                        ""
                                                    }
                                                    id={`${field.name}-${elIndex}`}
                                                    type={field.type}
                                                    onChange={(e) =>
                                                        onChange(
                                                            field.name,
                                                            e,
                                                            el.data,
                                                            el.action
                                                        )
                                                    }
                                                    style={{}}
                                                    invalid={
                                                        errors.indexOf(
                                                            `${el.type}_${
                                                                field.name
                                                            }${
                                                                index
                                                                    ? index
                                                                    : ""
                                                            }`
                                                        ) > -1
                                                    }
                                                />
                                            </Col>
                                        );
                                    })}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                );
            })}
        </>
    );
};

export default ManagerFormComponent;
