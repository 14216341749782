import React, { useState, useEffect } from "react";
/** LIB */
import { useSelector } from "react-redux";
/** COMPONENTS */
import { Backdrop, CircularProgress, Fab } from "@mui/material";
import { Input, Container, Row, Col, Card, CardBody } from "reactstrap";
import TreeComponent from "./components/tree.component";
import TableComponent from "./components/table.component";
import { formatTree, addNodeUnderParent } from "../../utils/site.utils";
import { sleep } from "../../utils/global.utils";
import CardHeader from "reactstrap/lib/CardHeader";
import WarningComponent from "../../components/Global/warning.component";
import Layout from "../../components/Global/layout.component";
/** SERVICES */
import { getSiteObjects, updateLinks } from "../../services/site.service";

const LinkPage = (props) => {
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const selectedSite = useSelector((state) => state.designStore.selectedSite);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [source1, setSource1] = useState(null);
    const [source2, setSource2] = useState(null);
    const [sources, setSources] = useState([]);
    const [currentSelection, setCurrentSelection] = useState([[], []]);
    const [selections, setSelections] = useState([]);
    const [brothers, setBrothers] = useState([]);
    const [alert, showAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    useEffect(() => {
        if (selectedSite && selectedSite.Hash) {
            init();
        }
    }, [selectedSite]);

    const init = async () => {
        setObjects([]);
        let res = await getSiteObjects(selectedSite.Hash);
        if (!res.success) {
            setAlertMessage(res.errorMessage);
            showAlert(true);
        } else {
            if (res.objects.length === 0) {
                setAlertMessage(
                    "Aucun objet n'est présent dans la base de données"
                );
            } else {
                setObjects(res.objects);
                let sources = res.objects
                    .filter((el) => el.ObjectSourceId)
                    .filter((item, pos) => {
                        return (
                            res.objects
                                .map((el) => el.ObjectSourceId)
                                .indexOf(item.ObjectSourceId) == pos
                        );
                    });
                console.log("sources", sources);
                if (sources?.length > 0) {
                    let find = sources.find(
                        (el) => el.SourceType === "Geometry"
                    );
                    if (find) {
                        setSource1(find.ObjectSourceId);
                        let filtered_data = res.objects.filter(
                            (el) =>
                                el.ObjectSourceId == find.ObjectSourceId ||
                                el.ObjectSourceId == null
                        );
                        console.log("filtered_data", filtered_data);
                        let data1 = await formatTree(filtered_data);
                        setData1(data1);
                    }
                }

                if (sources?.length > 1) {
                    let find = sources.find(
                        (el) => el.SourceType !== "Geometry"
                    );
                    if (find) {
                        let filtered_data = res.objects.filter(
                            (el) =>
                                el.ObjectSourceId == find.ObjectSourceId ||
                                el.ObjectSourceId == null
                        );
                        console.log("filtered_data", filtered_data);
                        let data2 = await formatTree(filtered_data);
                        setData2(data2);
                        setSource2(find.ObjectSourceId);
                        initTable(
                            find.ObjectSourceId,
                            res.brothers,
                            res.objects
                        );
                    }
                }
                setBrothers(res.brothers);
                setSources(sources);
            }
        }
    };

    const initTable = (id, brothers, objects) => {
        let output = [];
        for (let i = 0; i < brothers.length; i++) {
            let source = objects.find(
                (el) => el.ObjectId == brothers[i].SourceId
            );
            let target = objects.find(
                (el) =>
                    el.ObjectId == brothers[i].TargetId &&
                    el.ObjectSourceId == id
            );
            if (source && target) {
                let exist = false;
                for (let j = 0; j < output.length; j++) {
                    let item = output[j];
                    let sourceFound = item[0].find(
                        (el) => el.id == source.ObjectId
                    );
                    if (sourceFound) {
                        output[j][1].push({
                            ...target,
                            id: target.ObjectId,
                            title: target.Name,
                            index: id,
                        });
                        exist = true;
                        break;
                    }
                    let targetFound = item[1].find(
                        (el) => el.id == target.ObjectId
                    );
                    if (sourceFound) {
                        output[j][0].push({
                            ...source,
                            id: source.ObjectId,
                            title: source.Name,
                            index: 1,
                        });
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    output.push([
                        [
                            {
                                ...source,
                                id: source.ObjectId,
                                title: source.Name,
                                index: 1,
                            },
                        ],
                        [
                            {
                                ...target,
                                id: target.ObjectId,
                                title: target.Name,
                                index: id,
                            },
                        ],
                    ]);
                }
            }
        }
        setSelections(output);
    };

    const getNodeKey = ({ node }) => node.id;

    const changeSelection = (index, node, path) => {
        console.log("node", node);
        if (index == 1) {
            let previous = currentSelection[0].find((el) => el.id == node.id);
            currentSelection[0] = currentSelection[0].map((el) => {
                if (el.id == node.id) return null;
                else return el;
            });
            if (
                !previous &&
                (currentSelection[0].length == 0 ||
                    currentSelection[1].length <= 1)
            ) {
                let alreadySelected = selections.find((el) => {
                    let found = el[0].find((item) => item.id == node.id);
                    return found;
                });
                if (!alreadySelected) {
                    currentSelection[0].push({ ...node, index });
                }
            }
        } else {
            let previous = currentSelection[1].find((el) => el.id == node.id);
            currentSelection[1] = currentSelection[1].map((el) => {
                if (el.id == node.id) return null;
                else return el;
            });
            if (
                !previous &&
                (currentSelection[1].length == 0 ||
                    currentSelection[0].length <= 1)
            ) {
                let alreadySelected = selections.find((el) => {
                    let found = el[1].find((item) => item.id == node.id);
                    return found;
                });
                if (!alreadySelected) {
                    currentSelection[1].push({ ...node, index });
                }
            }
        }
        console.log("changeSelection1", currentSelection);
        currentSelection[0] = currentSelection[0].filter((el) => el);
        currentSelection[1] = currentSelection[1].filter((el) => el);
        console.log("changeSelection2", currentSelection);
        setCurrentSelection(currentSelection);
    };

    const nodeClick = async (index, node, path) => {
        console.log(currentSelection);
        if (data1 && data2) {
            let data =
                index == 1
                    ? JSON.parse(JSON.stringify(data1))
                    : index == 2
                    ? JSON.parse(JSON.stringify(data2))
                    : null;
            if (data) {
                if (index == 1) setData1([...data]);
                if (index == 2) setData2([...data]);
                changeSelection(index, node, path);
            }
        }
    };

    const link = () => {
        if (currentSelection[0].length > 0 && currentSelection[1].length > 0) {
            setSelections((selections) => [...selections, currentSelection]);
            setCurrentSelection([[], []]);
        }
    };

    const removeSelection = (index) => {
        selections[index] = null;
        var removed = selections.filter((el) => el);
        console.log("removed", removed);
        setSelections(removed);
        setData1([...data1]);
        setData2([...data2]);
    };

    const saveLinks = async () => {
        setLoading(true);
        let output = [];
        let links = selections.map((el) => {
            let sourceIds = el[0].map((item) => item.id);
            let targetIds = el[1].map((item) => item.id);
            return { sourceIds, targetIds };
        });
        for (let i = 0; i < links.length; i++) {
            for (let j = 0; j < links[i].sourceIds.length; j++) {
                for (let k = 0; k < links[i].targetIds.length; k++) {
                    output.push({
                        SourceId: links[i].sourceIds[j],
                        TargetId: links[i].targetIds[k],
                    });
                }
            }
        }
        console.log("links", output);
        let res = await updateLinks(
            { links: output, source: source2, objects },
            selectedSite.Hash
        );
        await sleep(1000);
        setLoading(false);
    };

    const changeNodeSpaceType = (e) => {
        let newObjects = objects.map((el) => {
            if (el.ObjectId === e?.node?.id)
                return { ...el, SpaceType: e.value };
            else return el;
        });
        setObjects(newObjects);
    };

    return (
        <Layout title="Relations" showBuildings={true}>
            <Container style={{ paddingTop: 10 }}>
                {objects?.length > 0 && (
                    <Col sm="12">
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <Input
                                            // style={{ maxWidth: "200" }}
                                            type="select"
                                            disabled={true}
                                            value={source1}
                                        >
                                            {sources?.map((el) => (
                                                <option
                                                    value={el.ObjectSourceId}
                                                >
                                                    {el.SourceType}
                                                </option>
                                            ))}
                                        </Input>
                                    </CardHeader>
                                    <CardBody style={{ overflow: "scroll" }}>
                                        {data1 && (
                                            <TreeComponent
                                                data={data1 || []}
                                                nodeClick={nodeClick}
                                                index={1}
                                                setData={setData1}
                                                currentSelection={
                                                    currentSelection
                                                }
                                                selections={selections}
                                                canDrag={false}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <Input
                                            // style={{ maxWidth: "200" }}
                                            type="select"
                                            onChange={(e) => {
                                                if (e.target.value != source1) {
                                                    let find = sources.find(
                                                        (el) =>
                                                            el.ObjectSourceId ==
                                                            e.target.value
                                                    );
                                                    if (find) {
                                                        let filtered_data =
                                                            objects.filter(
                                                                (el) =>
                                                                    el.ObjectSourceId ==
                                                                        find.ObjectSourceId ||
                                                                    el.ObjectSourceId ==
                                                                        null
                                                            );
                                                        let data2 =
                                                            formatTree(
                                                                filtered_data
                                                            );
                                                        setData2(data2);
                                                        setSource2(
                                                            e.target.value
                                                        );
                                                        initTable(
                                                            find.ObjectSourceId,
                                                            brothers,
                                                            objects
                                                        );
                                                    }
                                                }
                                            }}
                                            value={source2}
                                        >
                                            {sources?.map((el) => (
                                                <option
                                                    value={el.ObjectSourceId}
                                                    disabled={
                                                        el.ObjectSourceId ==
                                                        source1
                                                    }
                                                >
                                                    {el.SourceType}
                                                </option>
                                            ))}
                                        </Input>
                                    </CardHeader>
                                    <CardBody style={{ overflow: "scroll" }}>
                                        {data2 && (
                                            <TreeComponent
                                                data={data2 || []}
                                                nodeClick={nodeClick}
                                                index={2}
                                                setData={setData2}
                                                currentSelection={
                                                    currentSelection
                                                }
                                                selections={selections}
                                                canDrag={false}
                                                changeNodeSpaceType={
                                                    changeNodeSpaceType
                                                }
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader>Correspondances</CardHeader>
                                    <CardBody>
                                        <TableComponent
                                            selections={selections}
                                            removeSelection={removeSelection}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                )}
                {(objects.length == 0 || loading) && !alert && (
                    <Backdrop
                        open={true}
                        style={{ color: "#fff", zIndex: 1000 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}

                {objects?.length > 0 && (
                    <>
                        <Fab
                            color="secondary"
                            aria-label="add"
                            style={{ position: "fixed", bottom: 30, right: 10 }}
                            onClick={saveLinks}
                        >
                            <i
                                className="fa fa-save"
                                style={{ fontSize: 18 }}
                            />
                        </Fab>

                        <Fab
                            color="secondary"
                            aria-label="add"
                            style={{
                                position: "fixed",
                                bottom: 100,
                                right: 10,
                            }}
                            onClick={link}
                        >
                            <i
                                className="fa fa-link"
                                style={{ fontSize: 18 }}
                            />
                        </Fab>
                    </>
                )}
                {alert && <WarningComponent>{alertMessage}</WarningComponent>}
            </Container>
        </Layout>
    );
};

export default LinkPage;
