import { store } from "../../store/configure.store";
import LocaleSlice from "../../store/slices/locale.slice";
import en_locale from "../../locales/en";
import fr_locale from "../../locales/fr";

const availableLanguages = ["en", "fr"];

export const getAvailableLanguage = () => {
    return availableLanguages;
};

export const getDefaultLanguage = () => {
    const language = navigator.language.split("-")[0];
    if (availableLanguages.includes(language)) return language;
    return availableLanguages[0];
};

export const loadLocale = async (language) => {
    if (language === "en") {
        return en_locale;
    } else return fr_locale;
};

export const setLocale = async (language) => {
    store.dispatch(LocaleSlice.actions.setLanguage(language));
    let keys = await loadLocale(language);
    console.log("keys>>", keys);
    if (keys) {
        store.dispatch(LocaleSlice.actions.setKeys(keys));
    }
};

export const replace = (str, obj) => {
    if (str) {
        for (let key of Object.keys(obj)) {
            let regex = "{{" + key + "}}";
            str = str.replace(new RegExp(regex, "g"), obj[key]);
        }
    }
    return str;
};
